/* eslint-disable @typescript-eslint/no-floating-promises */
import create from "zustand";

import {
    apiFetchProtected,
    errorAwareApiFetchProtected,
} from "../../../../../apiBindings";
import createFieldSlices from "../../../../../utils/store/fields";

const baseUrl = "/call-for-tenders/admin/crafts";

const useStore = create((set, get) => ({
    ...createFieldSlices("craftToAdd", "", set),

    loading: false,
    saving: false,
    deleting: false,
    crafts: undefined,
    validationErrors: undefined,

    fetchCrafts: () => {
        set({ loading: true });
        apiFetchProtected(baseUrl).then((response) => {
            set({
                crafts: response.data,
                loading: false,
            });
        });
    },
    saveCraft: (onSuccess, onError) => {
        set({ saving: true, validationErrors: undefined });
        const payload = {
            name: get().craftToAdd,
        };

        errorAwareApiFetchProtected(baseUrl, {
            method: "POST",
            body: JSON.stringify(payload),
        })
            .then((response) => {
                if (response?.data) {
                    set({ craftToAdd: "", validationErrors: undefined });
                    if (onSuccess) {
                        onSuccess(response.data);
                    }
                }
            })
            .catch((error) => {
                if (error.response.status === 422) {
                    set({ validationErrors: error.data });
                }
                if (onError) {
                    onError("Es ist ein Fehler aufgetreten");
                }
            })
            .finally(() => set({ saving: false }));
    },
    deleteCraft: (id, onDelete, onError) => {
        set({ deleting: true });
        const url = `${baseUrl}/${id}`;
        apiFetchProtected(url, {
            method: "DELETE",
        })
            .then((response) => {
                if (response.data) {
                    set({ crafts: response.data });
                    if (onDelete) {
                        onDelete(response.data);
                    }
                    return;
                }
                onError(response.message);
            })
            .finally(() => set({ deleting: false }));
    },
}));

export default useStore;
