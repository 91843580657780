import React, { useState } from "react";

import { css, useTheme } from "@emotion/react";

import SelectInput from "../../../../shared/forms/NewSelectInput";

const SearchRadiusSelect = ({ selectedRadius = 25, onChange }) => {
    const theme = useTheme();
    const [availableRadi] = useState([
        { label: "Unbegrenzt", value: "" },
        { label: "10 km", value: 10 },
        { label: "25 km", value: 25 },
        { label: "50 km", value: 50 },
        { label: "100 km", value: 100 },
        { label: "200 km", value: 200 },
    ]);
    const [selected, setSelected] = useState(() => {
        return availableRadi.find((radius) => radius.value === selectedRadius);
    });

    const onLocalChange = (radius) => {
        setSelected(radius);
        onChange(radius.value);
    };

    const width = css`
        max-width: 100%;
        ${theme.breakpoints.from.small.css`
          max-width: 250px;
    `}
    `;

    return (
        <>
            <SelectInput
                name="search_radius"
                placeholder="Umkreissuche"
                options={availableRadi.map((radius) => {
                    return {
                        value: radius.value,
                        label: radius.label,
                    };
                })}
                value={selected}
                onChange={(event) => {
                    onLocalChange(event);
                }}
                css={width}
            />
        </>
    );
};

export default SearchRadiusSelect;
