import React from "react";

import Columns from "../../../../shared/Columns";
import LinkComponent from "../../../../shared/LinkComponent";
import NewHeadline from "../../../../shared/NewHeadline";
import Box from "../../components/Box";

const Landing = () => {
    return (
        <>
            <NewHeadline>Administration</NewHeadline>
            <Columns>
                <Columns.Column desktop="one-third" tablet="half" mobile="full">
                    <Box>
                        <NewHeadline color="blue">Projekte</NewHeadline>
                        <LinkComponent
                            color="blue"
                            link={{
                                href: "/ausschreibungsportal/admin/projekte",
                                text: "Verwalten",
                            }}
                        />
                    </Box>
                </Columns.Column>
                <Columns.Column desktop="one-third" tablet="half" mobile="full">
                    <Box>
                        <NewHeadline color="blue">Gewerke</NewHeadline>
                        <LinkComponent
                            color="blue"
                            link={{
                                href: "/ausschreibungsportal/admin/gewerke",
                                text: "Verwalten",
                            }}
                        />
                    </Box>
                </Columns.Column>
                <Columns.Column desktop="one-third" tablet="half" mobile="full">
                    <Box>
                        <NewHeadline color="blue">E-Mail Verteiler</NewHeadline>
                        <LinkComponent
                            color="blue"
                            link={{
                                href: "/ausschreibungsportal/admin/verteiler",
                                text: "Verwalten",
                            }}
                        />
                    </Box>
                </Columns.Column>
                <Columns.Column desktop="one-third" tablet="half" mobile="full">
                    <Box>
                        <NewHeadline color="blue">
                            Vertragsbedingungen
                        </NewHeadline>
                        <LinkComponent
                            color="blue"
                            link={{
                                href: "/ausschreibungsportal/admin/vertragsbedingungen",
                                text: "Verwalten",
                            }}
                        />
                    </Box>
                </Columns.Column>

                <Columns.Column desktop="one-third" tablet="half" mobile="full">
                    <Box>
                        <NewHeadline color="blue">
                            Registrierte Unternehmen
                        </NewHeadline>
                        <LinkComponent
                            color="blue"
                            link={{
                                href: "/ausschreibungsportal/admin/registrierte-unternehmen",
                                text: "Verwalten",
                            }}
                        />
                    </Box>
                </Columns.Column>
            </Columns>
        </>
    );
};

export default Landing;
