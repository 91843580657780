import React, { useEffect, useState } from "react";

import { css } from "@emotion/react";
import styled from "@emotion/styled";

import HeadLine from "../../../../shared/NewHeadline";
import Box from "../../components/Box";
import TenderItem from "../TenderItem";

const TenderContainer = styled.div`
    margin-top: 2em;
`;

const TenderList = ({ tenders, userRadius }) => {
    const [tendersWithinRadius, setTendersWithinRadius] = useState([]);
    const [tendersOutsideRadius, setTendersOutsideRadius] = useState([]);
    const [tendersFinished, setTendersFinished] = useState([]);

    useEffect(() => {
        if (!userRadius) {
            setTendersWithinRadius(tenders);
        }

        const within = [];
        const outside = [];
        const finished = [];

        tenders.forEach((tender) => {
            if (
                (!userRadius || //unlimited
                    parseFloat(tender.distance_to_auth_user_km) <=
                        userRadius) &&
                tender.state.id !== 3
            ) {
                within.push(tender);
            } else if (tender.state.id === 3) {
                finished.push(tender);
            } else {
                outside.push(tender);
            }
        });

        setTendersWithinRadius(within);
        setTendersOutsideRadius(outside);
        setTendersFinished(finished);
    }, [tenders, userRadius]);

    if (!tenders || tenders.length < 1) {
        return (
            <Box>
                <div>Es wurden keine Ausschreibungen gefunden.</div>
            </Box>
        );
    }

    return (
        <>
            <TenderContainer>
                {tendersWithinRadius.map((tender) => (
                    <Box key={tender.id}>
                        <TenderItem tender={tender} showLink={true} />
                    </Box>
                ))}

                {tendersOutsideRadius.length > 0 && (
                    <>
                        <HeadLine
                            lookLike={4}
                            color="red"
                            css={css`
                                padding: 25px 0 5px;
                            `}
                        >
                            Die folgenden Ausschreibungen befinden sich
                            außerhalb Deiner Umkreissuche:
                        </HeadLine>
                        {tendersOutsideRadius.map((tender) => (
                            <Box key={tender.id}>
                                <TenderItem tender={tender} showLink={true} />
                            </Box>
                        ))}
                    </>
                )}

                {tendersFinished.length > 0 && (
                    <>
                        <HeadLine
                            lookLike={4}
                            color="red"
                            css={css`
                                padding: 25px 0 5px;
                            `}
                        >
                            Die folgenden Ausschreibungen sind bereits beendet:
                        </HeadLine>
                        {tendersFinished.map((tender) => (
                            <Box key={tender.id}>
                                <TenderItem tender={tender} showLink={true} />
                            </Box>
                        ))}
                    </>
                )}
            </TenderContainer>
        </>
    );
};

export default TenderList;
