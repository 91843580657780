/**
 * These fields and functions are usefull for form validation fields in a zustand store.
 *
 * @param {*} set
 * @param {*} get
 * @returns
 */
const createFormErrorSlices = (set, get) => ({
    errors: [],
    setErrors: (errors) => {
        set({ errors });
    },
    addError: (error) => {
        set((state) => {
            if (state.errors.includes(error)) {
                return state;
            }
            return { errors: [...state.errors, error] };
        });
    },
    clearError: (error) =>
        set((state) => ({
            errors: state.errors.filter((item) => item !== error),
        })),
    hasErrorAt: (error) => get().errors.includes(error),
});

export default createFormErrorSlices;
