import React from "react";

import { css } from "@emotion/react";

import { toReadableDate } from "../../../../../utils/DateHelpers";
import Columns from "../../../../shared/Columns";
import Message from "../../../../shared/Message";
import Button from "../../../../shared/NewForms/Button";
import Headline from "../../../../shared/NewHeadline";
import Box from "../Box";
import DownloadLink from "../DownloadLink";

const FileList = ({
    files,
    title = "",
    onDelete,
    deleting = false,
    withEmptyMessage = true,
}) => {
    const wordBreak = css`
        word-break: break-all;
    `;
    return (
        <>
            <section>
                {title && (
                    <Headline lookLike={4} color="blue">
                        {title}
                    </Headline>
                )}

                {withEmptyMessage && files.length < 1 && (
                    <Message color="grey">Keine Dokumente gefunden.</Message>
                )}

                {files.map((file) => (
                    <Box key={file.id}>
                        <Columns>
                            <Columns.Column desktop="half">
                                <DownloadLink file={file} css={wordBreak} />
                            </Columns.Column>
                            {file.file_type && (
                                <Columns.Column>
                                    {file.file_type.type}
                                </Columns.Column>
                            )}
                            <Columns.Column>{file.extension}</Columns.Column>

                            <Columns.Column>
                                {file.filesize_kilobyte} kb
                            </Columns.Column>
                            <Columns.Column>
                                {toReadableDate(file.updated_at)}
                            </Columns.Column>
                            {onDelete && (
                                <Columns.Column>
                                    <Button
                                        type="button"
                                        color="redOutline"
                                        onClick={() => onDelete(file)}
                                        disabled={deleting}
                                    >
                                        Löschen
                                    </Button>
                                </Columns.Column>
                            )}
                        </Columns>
                    </Box>
                ))}
            </section>
        </>
    );
};

export default FileList;
