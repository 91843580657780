import React, { useEffect } from "react";

import styled from "@emotion/styled";

import Columns from "../../../../shared/Columns";
import { navigate } from "../../../../shared/LinkComponent";
import LoadingIndicator from "../../../../shared/LoadingIndicator";
import Button from "../../../../shared/NewForms/Button";
import NewHeadline from "../../../../shared/NewHeadline";
import CraftPills from "../../components/CraftPills";
import SearchRadiusSelect from "../../components/SearchRadiusSelect";
import TenderList from "../../components/TenderList";
import useStore from "../../Dashboard/store";
import TermsOfService from "../TermsOfService";

const ActionRow = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-bottom: 10px;
`;

const ActionInline = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const Dashboard = () => {
    const {
        account,
        accountCrafts,
        loading,
        tenders,
        fetchTenders,
        saveSearchRadius,
        unacceptedTermsOfService,
        tendersCountInRange,
    } = useStore();

    useEffect(() => {
        //Always fetch tenders if the User comes to the landing page
        fetchTenders();
    }, []);

    const onRadiusChange = (radius) => {
        saveSearchRadius(radius, () => {
            fetchTenders();
        });
    };

    if (unacceptedTermsOfService.length > 0) {
        return (
            <TermsOfService
                termsOfService={account.unaccepted_terms_of_service}
            />
        );
    }

    return (
        <>
            <NewHeadline>Ausschreibungen</NewHeadline>
            {loading && <LoadingIndicator />}
            {!loading && account && (
                <>
                    <ActionRow>
                        <div>
                            Meine Gewerke:
                            <CraftPills limit={5} crafts={accountCrafts} />
                        </div>
                        <ActionInline>
                            <SearchRadiusSelect
                                selectedRadius={account.interest_radius_km}
                                onChange={onRadiusChange}
                            />

                            {account.is_cft_admin === true && (
                                <Button
                                    onClick={() =>
                                        void navigate(
                                            "/ausschreibungsportal/admin/projekte"
                                        )
                                    }
                                    small
                                    color="redOutline"
                                >
                                    Projektübersicht
                                </Button>
                            )}
                        </ActionInline>
                    </ActionRow>

                    <Columns>
                        <Columns.Column>
                            {!account.interest_radius_km && (
                                <NewHeadline color="red" lookLike={4}>
                                    {tendersCountInRange} Ausschreibungen
                                    gefunden.
                                </NewHeadline>
                            )}

                            {account.interest_radius_km && (
                                <NewHeadline color="red" lookLike={4}>
                                    {tendersCountInRange} Ausschreibungen im
                                    Umkreis von {account?.interest_radius_km} km
                                    gefunden.
                                </NewHeadline>
                            )}

                            <TenderList
                                userRadius={account?.interest_radius_km}
                                tenders={tenders}
                            />
                        </Columns.Column>
                    </Columns>
                    <Columns>
                        <Pagination />
                    </Columns>
                </>
            )}
        </>
    );
};

const CenteredContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`;

const Pagination = () => {
    const { tendersMeta, fetchMoreTenders, loadingMore } = useStore();

    if (loadingMore) {
        return (
            <CenteredContainer>
                <LoadingIndicator />
            </CenteredContainer>
        );
    }
    return (
        <>
            {tendersMeta && (
                <Columns.Column>
                    {tendersMeta.current_page < tendersMeta.last_page && (
                        <Button
                            centered
                            color="redOutline"
                            onClick={fetchMoreTenders}
                        >
                            Mehr anzeigen
                        </Button>
                    )}
                </Columns.Column>
            )}
        </>
    );
};

export default Dashboard;
