/* eslint-disable @typescript-eslint/no-floating-promises */
import create from "zustand";

import { apiFetchProtected } from "../../../../../apiBindings";

const baseUrl = "/call-for-tenders/admin/distribution-lists";

const useStore = create((set, get) => ({
    loading: false,
    lists: [],
    fetchLists: () => {
        set({ loading: true });
        apiFetchProtected(baseUrl).then((response) => {
            set({
                lists: response.data,
                loading: false,
            });
        });
    },
    getListById: (id) => {
        const lists = get().lists;
        return lists.find((list) => Number(list.id) === Number(id));
    },

    deleteListById: (id, onSuccess) => {
        set({ loading: true });
        apiFetchProtected(`${baseUrl}/${id}`, {
            method: "DELETE",
        }).then((response) => {
            set({
                lists: response.data,
                loading: false,
            });
            onSuccess();
        });
    },
}));

export default useStore;
