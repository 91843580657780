import React from "react";

import Headline from "../../../../../shared/NewHeadline";
import Form from "../Form";

const Create = () => {
    return (
        <>
            <Headline>E-Mail Verteiler anlegen</Headline>
            <Form />
        </>
    );
};

export default Create;
