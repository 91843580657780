import React, { useEffect, useState } from "react";

import styled from "@emotion/styled";

const STATE_MAP = [
    { id: 1, name: "Entwurf", color: "yellow" },
    { id: 2, name: "Aktiv", color: "green" },
    { id: 3, name: "Beendet", color: "grey" },
];

const Container = styled.div`
    display: flex;
`;

const TenderState = ({ state }) => {
    const [mappedState, setMappedState] = useState(undefined);

    useEffect(() => {
        if (state.id) {
            const stateObj = STATE_MAP.find(
                (stateMap) => stateMap.id === state.id
            );
            setMappedState(stateObj);
        }
    }, []);

    if (mappedState) {
        const Indicator = styled.div`
            width: 10px;
            height: 10px;
            margin-top: 6px;
            margin-right: 5px;
            background: ${mappedState.color};
            border-radius: 50px;
        `;

        return (
            <Container>
                <Indicator />
                <div>{mappedState.name}</div>
            </Container>
        );
    }
    return null;
};

export default TenderState;
