import React, { useEffect, useState } from "react";

import styled from "@emotion/styled";

import DatePicker from "../../../../../shared/forms/DatePicker";
import FormError from "../../../../../shared/forms/FormError";
import NewInput from "../../../../../shared/forms/NewInput";
import { SalutationSelect } from "../../../../../shared/forms/NewSelect";
import { navigate } from "../../../../../shared/LinkComponent";
import { SearchInputWithGoogleMapsScript } from "../../../../../shared/maps/SearchInput";
import Button from "../../../../../shared/NewForms/Button";
import NewHeadline from "../../../../../shared/NewHeadline";
import useSnackbar from "../../../../../shared/Snackbar/store";
import AddressModal from "../../../components/AddressModal";
import FileHandler from "../../../components/FileHandler";
import ValidationErrors from "../../../components/ValidationErrors";
import useProjectsStore from "../store";
import useStore from "./store";

const AddressContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px;
    border: 1px solid ${({ theme }) => theme.colors.darkGrey.toString()};
    border-radius: 4px;
`;

const Form = ({ project }) => {
    const {
        data,
        setData,
        setDataField,
        saving,
        deleting,
        deleteFile,
        setFileUploadName,
        addFileUploads,
        truncateData,
        save,
        errors,
        hasErrorAt,
        addError,
        clearError,
        setErrors,
        isModalVisible,
        setIsModalVisible,
        validationErrors,
    } = useStore();
    const { fetchProjects } = useProjectsStore();
    const { notify } = useSnackbar();
    const [showPlacesSelect, setShowPlacesSelect] = useState(false);

    useEffect(() => {
        if (project) {
            setData({
                id: project.id,
                name: project.name,
                description: project.description,
                street: project.street || "",
                street_number: project.street_number || "",
                zipcode: project.zipcode || "",
                city: project.city || "",
                lat: project.lat,
                long: project.long,
                estimatedStartAt: project.estimated_start_at,
                estimatedEndAt: project.estimated_end_at,
                contactSalutation: project.contact_salutation || "Herr",
                contactFirstname: project.contact_firstname,
                contactLastname: project.contact_lastname,
                contactPhone: project.contact_phone,
                contactEmail: project.contact_email,
                ownerName: project.owner_name,
                ownerStreet: project.owner_street,
                ownerStreetNumber: project.owner_street_number,
                ownerZipcode: project.owner_zipcode,
                ownerCity: project.owner_city,
                files: project.files,
                fileUploads: [],
                fileUploadNames: [],
            });
        } else {
            truncateData();
        }
    }, [project]);

    const onChange = (event) => {
        setDataField(event.target.name, event.target.value);
    };

    const onSubmit = (event) => {
        event.preventDefault();

        let localErrors = [];
        Object.keys(data).forEach((key) => {
            if (!["id"].includes(key) && !["street_number"].includes(key)) {
                if (
                    (data[key] === "" || data[key] === null) &&
                    !hasErrorAt(key)
                ) {
                    localErrors.push(key);
                }
            }
        });

        setErrors(localErrors);

        if (errors.length === 0 && localErrors.length === 0) {
            save((project) => {
                notify(
                    "Erfolgreich",
                    `Das Projekt ${project.name} wurde erfolgreich gespeichert`
                );
                fetchProjects();
                void navigate("/ausschreibungsportal/admin/projekte");
            });
        } else {
            let id = [...errors, ...localErrors][0];
            if (id === "street" || id === "zipcode" || id === "city") {
                id = "location";
            }
            const element = document.getElementById(id);
            if (element) {
                element.focus();
            }
        }
    };

    const onPlacesChange = (placesData) => {
        setDataField("lat", placesData[0].geometry.location.lat());
        setDataField("long", placesData[0].geometry.location.lng());
        placesData[0].address_components.forEach((item) => {
            if (item.types.includes("route")) {
                setDataField("street", item.long_name);
            }
            if (item.types.includes("street_number")) {
                setDataField("street_number", item.long_name);
            }
            if (item.types.includes("locality")) {
                setDataField("city", item.long_name);
            }
            if (item.types.includes("postal_code")) {
                setDataField("zipcode", item.long_name);
            }
            if (item.types.includes("street_number")) {
                setDataField("zipcode", item.long_name);
            }
        });
        clearError("street");
        clearError("city");
        clearError("zipcode");
        clearError("street_number");
        setShowPlacesSelect(false);
    };

    const handleModal = (event) => {
        if ((event && event.target.classList.contains("backdrop")) || !event) {
            setIsModalVisible(!isModalVisible);
        }
    };

    return (
        <form onSubmit={onSubmit}>
            <NewInput
                name="name"
                placeholder="Name"
                value={data.name}
                onChange={onChange}
                onError={() => addError("name")}
                onErrorSolved={() => clearError("name")}
                forceError={hasErrorAt("name")}
                withField
                required
            />
            <NewInput
                name="description"
                placeholder="Beschreibung"
                value={data.description}
                type="textarea"
                onChange={onChange}
                onError={() => addError("description")}
                onErrorSolved={() => clearError("description")}
                forceError={hasErrorAt("description")}
                withField
                required
            />
            <NewHeadline lookLike="4" color="blue">
                Adresse
            </NewHeadline>
            {!project && (
                <>
                    <SearchInputWithGoogleMapsScript
                        name="location"
                        placeholder="Adresse suchen"
                        onPlacesChange={onPlacesChange}
                        onKeyPress={(e) => {
                            e.key === "Enter" && e.preventDefault();
                        }}
                    />
                    {(hasErrorAt("city") ||
                        hasErrorAt("zipcode") ||
                        hasErrorAt("street_number") ||
                        hasErrorAt("street")) && (
                        <FormError message="Gib bitte eine Adresse ein." />
                    )}
                    <NewInput
                        name="street_number"
                        placeholder="Hausnummer"
                        value={data.street_number}
                        onChange={onChange}
                        validation="house_number"
                        onError={() => addError("street_number")}
                        onErrorSolved={() => clearError("street_number")}
                        forceError={hasErrorAt("street_number")}
                        withField
                    />
                </>
            )}
            {project && (
                <AddressContainer>
                    <div>
                        {data.street} {data.street_number}
                        <br />
                        {data.zipcode} {data.city}
                        <br />
                        {data.country}
                        {(hasErrorAt("city") ||
                            hasErrorAt("zipcode") ||
                            hasErrorAt("street_number") ||
                            hasErrorAt("street")) && (
                            <FormError message="Gib bitte eine vollständige Adresse ein." />
                        )}
                    </div>
                    <AddressModal handleModal={handleModal} store={useStore} />
                </AddressContainer>
            )}
            <NewHeadline lookLike="3" color="blue">
                Zeitraum
            </NewHeadline>
            <DatePicker
                name="estimatedStartAt"
                label="Startdatum"
                value={data.estimatedStartAt ?? new Date()}
                onChange={(value) => setDataField("estimatedStartAt", value)}
            />
            <DatePicker
                name="estimatedEndAt"
                label="Enddatum"
                value={data.estimatedEndAt ?? new Date()}
                onChange={(value) => setDataField("estimatedEndAt", value)}
            />
            <NewHeadline lookLike="3" color="blue">
                Kontaktperson
            </NewHeadline>
            <SalutationSelect
                name="contactSalutation"
                value={data.contactSalutation}
                forceError={hasErrorAt("contactSalutation")}
                onChange={onChange}
                withField
            />
            <NewInput
                name="contactFirstname"
                placeholder="Vorname"
                value={data.contactFirstname}
                onChange={onChange}
                onError={() => addError("contactFirstname")}
                onErrorSolved={() => clearError("contactFirstname")}
                forceError={hasErrorAt("contactFirstname")}
                withField
                validation="name"
                required
            />
            <NewInput
                name="contactLastname"
                placeholder="Nachname"
                value={data.contactLastname}
                onChange={onChange}
                onError={() => addError("contactLastname")}
                onErrorSolved={() => clearError("contactLastname")}
                forceError={hasErrorAt("contactLastname")}
                withField
                validation="name"
            />
            <NewInput
                name="contactEmail"
                placeholder="E-Mail"
                value={data.contactEmail}
                onChange={onChange}
                onError={() => addError("contactEmail")}
                onErrorSolved={() => clearError("contactEmail")}
                forceError={hasErrorAt("contactEmail")}
                withField
                validation="email"
                required
            />
            <NewInput
                name="contactPhone"
                placeholder="Telefon"
                value={data.contactPhone}
                onChange={onChange}
                onError={() => addError("contactPhone")}
                onErrorSolved={() => clearError("contactPhone")}
                forceError={hasErrorAt("contactPhone")}
                withField
                validation="phone"
                required
            />

            <NewHeadline lookLike="3" color="blue">
                Bauherr
            </NewHeadline>

            <NewInput
                name="ownerName"
                placeholder="Name"
                value={data.ownerName}
                onChange={onChange}
                onError={() => addError("ownerName")}
                onErrorSolved={() => clearError("ownerName")}
                forceError={hasErrorAt("ownerName")}
                withField
                validation="regCompany"
            />

            <NewInput
                name="ownerStreet"
                placeholder="Straße"
                value={data.ownerStreet}
                onChange={onChange}
                validation="street"
                onError={() => addError("ownerStreet")}
                onErrorSolved={() => clearError("ownerStreet")}
                forceError={hasErrorAt("ownerStreet")}
                withField
            />

            <NewInput
                name="ownerStreetNumber"
                placeholder="Hausnummer"
                value={data.ownerStreetNumber}
                onChange={onChange}
                validation="house_number"
                onError={() => addError("ownerStreetNumber")}
                onErrorSolved={() => clearError("ownerStreetNumber")}
                forceError={hasErrorAt("ownerStreetNumber")}
                withField
            />

            <NewInput
                name="ownerZipcode"
                placeholder="Postleitzahl"
                value={data.ownerZipcode}
                onChange={onChange}
                validation="zipcode"
                onError={() => addError("ownerZipcode")}
                onErrorSolved={() => clearError("ownerZipcode")}
                forceError={hasErrorAt("ownerZipcode")}
                withField
            />

            <NewInput
                name="ownerCity"
                placeholder="Ort"
                value={data.ownerCity}
                onChange={onChange}
                validation="city"
                onError={() => addError("ownerCity")}
                onErrorSolved={() => clearError("ownerCity")}
                forceError={hasErrorAt("ownerCity")}
                withField
            />

            <FileHandler
                data={data}
                deleting={deleting}
                deleteFile={deleteFile}
                setFileUploadName={setFileUploadName}
                addFileUploads={addFileUploads}
            />

            <ValidationErrors error={validationErrors} />
            <Button
                type="submit"
                centered
                color="redOutline"
                disabled={saving}
                loading={saving}
            >
                Speichern
            </Button>
        </form>
    );
};

export default Form;
