import React, { FC, useMemo, useState } from "react";

import { StandaloneSearchBox, useLoadScript } from "@react-google-maps/api";

import { googleMapsApiKey } from "../../../config";
import NewInput, { InputProps } from "../forms/NewInput";

export type SearchInputProps = {
    bounds?: google.maps.LatLngBoundsLiteral;
    onPlacesChange: (
        places: google.maps.places.PlaceResult[] | undefined,
    ) => void;
    className?: string;
    withIcon?: boolean;
} & InputProps<"input">;

const SearchInput: FC<SearchInputProps> = ({
    bounds,
    onPlacesChange,
    className,
    withIcon = false,
    ...props
}) => {
    const [searchBox, setSearchBox] = useState<google.maps.places.SearchBox>();

    return (
        <div {...{ className }}>
            <StandaloneSearchBox
                {...{ bounds }}
                onLoad={setSearchBox}
                onPlacesChanged={() => onPlacesChange(searchBox?.getPlaces?.())}
            >
                <NewInput
                    {...(withIcon
                        ? {
                              buttonIcon: "search",
                              buttonColor: "blue",
                              // eslint-disable-next-line @typescript-eslint/no-empty-function
                              handleClick: () => {},
                          }
                        : {})}
                    {...props}
                />
            </StandaloneSearchBox>
        </div>
    );
};
export default SearchInput;

export type SearchInputWithGoogleMapsScriptProps = SearchInputProps;

export const SearchInputWithGoogleMapsScript: FC<
    SearchInputWithGoogleMapsScriptProps
> = ({ bounds, onPlacesChange, className, withIcon = true, ...props }) => {
    const libraries = useMemo(() => ["places" as const], []);
    const googleMaps = useLoadScript({
        googleMapsApiKey,
        libraries,
    });

    return googleMaps.isLoaded ? (
        <SearchInput
            withIcon={withIcon}
            {...{ bounds, onPlacesChange, className }}
            {...props}
        />
    ) : (
        <div {...{ className }}>
            <NewInput
                buttonIcon="search"
                buttonColor="blue"
                handleClick={
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    () => {}
                }
                {...props}
            />
        </div>
    );
};
