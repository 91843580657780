import create from "zustand";

import { apiFetchProtected } from "../../../../../apiBindings";

const useStore = create((set, get) => ({
    loading: false,
    crafts: undefined,

    fetchCrafts: () => {
        set({ loading: true });
        apiFetchProtected("/call-for-tenders/crafts")
            .then((response) => {
                set({
                    crafts: response.data,
                });
            })
            .finally(() => set({ loading: false }));
    },
}));

export default useStore;
