import create from "zustand";

import {
    apiFetchProtected,
    errorAwareApiFetchProtected,
} from "../../../../../apiBindings";

const baseUrl = "/call-for-tenders/admin/terms-of-service";

const defaultForm = {
    id: "",
    title: "",
    text: "",
    craft_id: "",
    file: "",
};

const useStore = create((set, get) => ({
    form: defaultForm,
    loading: false,
    saving: false,
    termsOfService: undefined,
    term: undefined,
    validationErrors: undefined,

    fetchTermById: (id) => {
        set({ loading: true });
        let url = `${baseUrl}/${id}`;
        apiFetchProtected(url)
            .then((response) => {
                set({ term: response.data });
            })
            .finally(() => set({ loading: false }));
    },

    fetchTermsOfService: () => {
        set({ loading: true });
        apiFetchProtected(baseUrl)
            .then((response) => {
                set({ termsOfService: response.data });
            })
            .finally(() => set({ loading: false }));
    },

    create: (onSuccess, onError) => {
        set({ saving: true, validationErrors: undefined });
        const { form } = get();

        const payload = new FormData();
        payload.append("title", form.title);
        payload.append("text", form.text);
        payload.append("craft_id", form.craft_id);
        payload.append("file", form.file);

        errorAwareApiFetchProtected(
            baseUrl,
            {
                method: "POST",
                body: payload,
            },
            true
        )
            .then((response) => {
                if (response?.data) {
                    set({ form: defaultForm });
                    set({
                        termsOfService: response.data,
                        validationErrors: undefined,
                    });
                    onSuccess(response.data);
                }
            })
            .catch((error) => {
                if (error.response.status === 422) {
                    set({ validationErrors: error.data });
                }
                if (onError) {
                    onError("Es ist ein Fehler aufgetreten.");
                }
            })
            .finally(() => set({ saving: false }));
    },

    update: (id, onSuccess, onError) => {
        set({ saving: true, validationErrors: undefined });
        const { form } = get();

        const payload = new FormData();
        payload.append("title", form.title);
        payload.append("text", form.text);
        payload.append("craft_id", form.craft_id);
        payload.append("file", form.file);

        errorAwareApiFetchProtected(
            `${baseUrl}/${id}/update`,
            {
                method: "POST",
                body: payload,
            },
            true
        )
            .then((response) => {
                if (response?.data) {
                    set({ form: defaultForm });
                    set({
                        termsOfService: response.data,
                        validationErrors: undefined,
                    });
                    if (onSuccess) {
                        onSuccess(response.data);
                    }
                }
            })
            .catch((error) => {
                if (error.response.status === 422) {
                    set({ validationErrors: error.data });
                }
                if (onError) {
                    onError("Es ist ein Fehler aufgetreten.");
                }
            })
            .finally(() => set({ saving: false }));
    },

    deleteTermsOfService: (termsOfServiceId, onSuccess, onError) => {
        set({ saving: true });
        let url = `${baseUrl}/${termsOfServiceId}`;
        apiFetchProtected(url, {
            method: "DELETE",
        })
            .then((response) => {
                if (response.data) {
                    set({ termsOfService: response.data });
                    if (onSuccess) {
                        onSuccess(response.data);
                    }
                }
            })
            .finally(() => set({ saving: false }));
    },

    setForm: (key, value) => {
        let form = get().form;
        form[key] = value;
        set({ form });
    },

    resetForm: () => {
        set({
            form: {
                id: "",
                title: "",
                text: "",
                craft_id: "",
                file: "",
            },
        });
    },
}));

export default useStore;
