import React, { useEffect } from "react";

import styled from "@emotion/styled";
import { useDebounce } from "use-debounce";

import NewInput from "../../../../shared/forms/NewInput";
import LinkComponent from "../../../../shared/LinkComponent";
import LoadingIndicator from "../../../../shared/LoadingIndicator";
import Button from "../../../../shared/NewForms/Button";
import NewHeadline from "../../../../shared/NewHeadline";
import Project from "../../components/Project";
import useStore from "./store";

const FilterArea = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 20px;
    ${({ theme }) => theme.breakpoints.from.small.css`
       flex-direction: row;
    `}
`;

const Projects = () => {
    const {
        loading,
        loadingMore,
        hasMore,
        projects,
        filter,
        setFilter,
        fetchProjects,
        loadMore,
    } = useStore();
    const [debouncedFilter] = useDebounce(filter, 500);

    useEffect(() => {
        fetchProjects();
    }, [debouncedFilter]);

    return (
        <div>
            <NewHeadline>Projekte</NewHeadline>
            <FilterArea>
                <NewInput
                    value={filter}
                    onChange={(event) => setFilter(event.target.value)}
                    placeholder="Filter"
                />
                <LinkComponent
                    button
                    link={{
                        href: "/ausschreibungsportal/admin/projekte/erstellen",
                        text: "Neues Projekt anlegen",
                    }}
                />
            </FilterArea>
            {loading ? (
                <LoadingIndicator />
            ) : (
                <>
                    {projects.length === 0 && (
                        <div>Keine Projekte vorhanden</div>
                    )}
                    {projects.map((project) => (
                        <Project key={project.id} project={project} />
                    ))}
                    {!loadingMore && hasMore && (
                        <Button
                            small
                            color="red"
                            centered
                            onClick={() => loadMore()}
                        >
                            Mehr anzeigen
                        </Button>
                    )}
                    {loadingMore && <LoadingIndicator />}
                </>
            )}
        </div>
    );
};

export default Projects;
