import React from "react";

import styled from "@emotion/styled";

const BoxWithShadow = styled.div`
    padding: 30px;
    margin-top: 20px;
    margin-bottom: 20px;
    overflow: hidden;
    border-radius: 6px;
    box-shadow: rgb(0 0 0 / 24%) 0 3px 8px;
`;

const Box = ({ children }) => {
    return <BoxWithShadow>{children}</BoxWithShadow>;
};

export default Box;
