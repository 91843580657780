import React, { useState } from "react";

import styled from "@emotion/styled";

import { apiDownloadProtected } from "../../../../../apiBindings";
import LinkComponent from "../../../../shared/LinkComponent";
import LoadingIndicator from "../../../../shared/LoadingIndicator";

const LoadingContainer = styled.div`
    display: flex;
    gap: 10px;
`;

const DownloadLink = ({ file, filename = null, ...props }) => {
    const [isLoading, setIsLoading] = useState(false);

    const download = (event, file) => {
        event.preventDefault();
        setIsLoading(true);
        const url = `/call-for-tenders/download/${file.id}`;
        apiDownloadProtected(url)
            .then((res) => res.blob())
            .then((blob) => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = url;
                a.download = filename ?? file.filename;
                a.target = "_blank";
                a.click();
                a.remove();
                setTimeout(() => window.URL.revokeObjectURL(url), 100);
            })
            .finally(() => setIsLoading(false));
    };

    if (isLoading) {
        return (
            <LoadingContainer>
                <span>Datei wird geladen</span>
                <LoadingIndicator />
            </LoadingContainer>
        );
    }

    return (
        <LinkComponent
            icon="download"
            moveIcon={false}
            onClick={(e) => download(e, file)}
            link={{
                text: filename ?? file.filename,
            }}
            {...props}
        >
            {filename ?? file.filename}
        </LinkComponent>
    );
};

export default DownloadLink;
