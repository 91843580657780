/* eslint-disable @typescript-eslint/no-floating-promises */
import create from "zustand";

import {
    apiFetchProtected,
    errorAwareApiFetchProtected,
} from "../../../../../apiBindings";
import createFieldSlices from "../../../../../utils/store/fields";

const baseUrl = "/call-for-tenders/admin/projects";

const getTenders = (projects) => {
    const allTenders = [];

    projects.forEach((project) => {
        project.tenders.forEach((tender) => {
            allTenders.push(tender);
        });
    });

    return allTenders;
};

const useStore = create((set, get) => ({
    ...createFieldSlices("filter", "", set),

    loading: false,
    deleting: false,
    loadingMore: false,
    projects: [],
    tenders: [],
    next: null,
    hasMore: false,
    fetchProjects: () => {
        const { filter } = get();
        set({ loading: true });
        apiFetchProtected(`${baseUrl}?filter=${filter}`).then((response) => {
            set({
                projects: response.data,
                tenders: getTenders(response.data),
                loading: false,
                next: response.links.next,
                hasMore: response.links.next !== null,
            });
        });
    },
    loadMore: () => {
        const state = get();

        if (state.next !== null) {
            set({ loadingMore: true });
            apiFetchProtected(
                `${baseUrl}${state.next}&filter=${state.filter}`
            ).then((response) => {
                const projects = [...state.projects, ...response.data];
                set({
                    projects,
                    tenders: getTenders(projects),
                    loading: false,
                    next: response.links.next,
                    hasMore: response.links.next !== null,
                    loadingMore: false,
                });
            });
        }
    },
    getProjectById: (id) => {
        const state = get();
        return state.projects.find(
            (project) => Number(project.id) === Number(id)
        );
    },
    getTenderById: (id) => {
        const state = get();
        return state.tenders.find((tender) => Number(tender.id) === Number(id));
    },
    getProjectForTenderId: (id) => {
        const state = get();
        const project = state.projects.find(
            (project) =>
                project.tenders?.find(
                    (tender) => Number(tender.id) === Number(id)
                ) !== null
        );

        return project;
    },
    /**
     * Update the project in the store, so we don't have to refetch everything.
     * If the project not exists, we add it.
     * @param {*} project
     */
    updateProject: (project) => {
        const { projects } = get();
        const item = projects.find((item) => {
            return Number(item.id) === Number(project.id);
        });

        if (item) {
            set({
                projects: projects.map((item) => {
                    if (Number(item.id) === Number(project.id)) {
                        return { ...item, ...project };
                    }
                    return item;
                }),
            });
        } else {
            if (!project.tenders) {
                project.tenders = [];
            }
            set({ projects: [...projects, project] });
        }
    },
    /**
     *  Deletes given Project.
     *
     * @param projectId
     * @param onError
     * @param onDelete
     */
    deleteProject: (projectId, onError, onDelete) => {
        set({ deleting: true });
        const url = `${baseUrl}/${projectId}`;
        errorAwareApiFetchProtected(url, {
            method: "DELETE",
        })
            .then((response) => {
                if (response.data) {
                    if (onDelete) {
                        onDelete(response.data);
                    }
                    return;
                }
                onError(response.message);
            })
            .finally(() => set({ deleting: false }));
    },
}));

export default useStore;
