/* eslint-disable @typescript-eslint/no-floating-promises */
import create from "zustand";

import {
    apiFetchProtected,
    errorAwareApiFetchProtected,
} from "../../../../apiBindings";
import createFieldSlices from "../../../../utils/store/fields";

const useStore = create((set, get) => ({
    ...createFieldSlices("isRegistered", false, set),
    ...createFieldSlices("accountCrafts", [], set),

    loading: false,
    loadingMore: false,
    tenders: [],
    tender: undefined,
    tendersTotalCount: 0,
    tendersCountInRange: 0,
    tendersMeta: undefined,
    tendersCount: 0,
    //Account
    accountLoaded: false,
    account: undefined,
    unacceptedTermsOfService: [],
    hasAuthError: false,

    fetchAuthAccount: () => {
        set({ accountLoaded: false, hasAuthError: false });

        errorAwareApiFetchProtected("/call-for-tenders/account", {
            method: "GET",
        })
            .then((response) => {
                set({
                    account: response.data,
                    accountCrafts: response.data?.crafts ?? [],
                    isRegistered: true,
                    unacceptedTermsOfService:
                        response.data.unaccepted_terms_of_service ?? [],
                });
            })
            .catch((error) => {
                //User needs to register an Account:
                if (error.response.status === 401) {
                    set({ isRegistered: false });
                }
                //Some other unexpected error occured Notify the user:
                else if (error.response.ok === false) {
                    set({ hasAuthError: true });
                }
            })
            .finally(() => set({ accountLoaded: true }));
    },

    fetchTenders: () => {
        set({ loading: true });
        apiFetchProtected("/call-for-tenders/account-tender")
            .then((response) => {
                set({
                    tenders: response.data,
                    tendersMeta: response?.meta ?? undefined,
                    tendersCount: response?.data?.length ?? 0,
                    tendersTotalCount: response.total_count_for_user,
                    tendersCountInRange: response.total_count_in_range,
                });
            })
            .finally(() => set({ loading: false }));
    },

    fetchMoreTenders: () => {
        set({ loadingMore: true });
        const { tendersMeta, tenders } = get();
        if (!tendersMeta) {
            return;
        }
        const page = tendersMeta.current_page + 1;
        apiFetchProtected(`/call-for-tenders/account-tender?page=${page}`)
            .then((response) => {
                const data = [...tenders, ...response.data];
                set({
                    tenders: data,
                    tendersMeta: response?.meta ?? undefined,
                    tendersCount: response?.data?.length ?? 0,
                    tendersTotalCount: response.total_count_for_user,
                    tendersCountInRange: response.total_count_in_range,
                });
            })
            .finally(() => set({ loadingMore: false }));
    },

    fetchTenderById: (id) => {
        set({ loading: true });
        apiFetchProtected(`/call-for-tenders/tenders/${id}`)
            .then((response) => {
                set({
                    tender: response.data,
                });
            })
            .finally(() => set({ loading: false }));
    },

    saveSearchRadius: (radius, onSuccess) => {
        set({ loading: true });
        const payload = {
            interest_radius_km: radius,
        };
        apiFetchProtected("/call-for-tenders/account/radius", {
            method: "PUT",
            body: JSON.stringify(payload),
        })
            .then((response) => {
                set({ account: response.data });
                if (onSuccess) {
                    onSuccess(response.data);
                }
            })
            .finally(() => set({ loading: false }));
    },

    setAccount: (account) => {
        set({ account });
        set({ accountCraft: account.crafts });
    },
}));

export default useStore;
