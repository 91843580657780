import React, { useState } from "react";

import styled from "@emotion/styled";

import { Permission, useHoyerAcl } from "../../../../../../hooks/useHoyerAcl";
import { navigate } from "../../../../../shared/LinkComponent";
import Button from "../../../../../shared/NewForms/Button";
import useSnackbar from "../../../../../shared/Snackbar/store";
import Modal from "../../../components/AddressModal/Modal";
import useStore from "../store";

const TextWrapper = styled.div`
    margin: 20px;
    font-size: 1.3rem;
    color: ${({ theme }) => theme.colors.blue.toString()};
`;

const ProjectDelete = ({ project }) => {
    const { notify } = useSnackbar();
    const { can } = useHoyerAcl();
    const [isActive, setIsActive] = useState(false);
    const [deleteProject, deleting, fetchProjects] = useStore((state) => [
        state.deleteProject,
        state.deleting,
        state.fetchProjects,
    ]);

    const handleModal = () => {
        setIsActive(!isActive);
    };

    const handleSubmit = () => {
        if (project.tenders.length > 0) {
            notify(
                "Projekt kann nicht gelöscht werden",
                `Das Projekt kann nicht gelöscht werden. Es müssen erst alle Ausschreibungen in diesem Projekt gelöscht werden.`
            );
        } else {
            deleteProject(
                project.id,
                () => {
                    notify(
                        "Erfolgreich gelöscht",
                        `Das Projekt wurde erfolgreich gelöscht.`
                    );
                    fetchProjects();
                    void navigate(`/ausschreibungsportal/admin/projekte/`);
                },
                (message) => {
                    notify("Fehler", message, "error");
                }
            );
        }
    };

    if (!can(Permission.AUSSCHREIBUNG_MANAGE)) {
        return null;
    }

    return (
        <>
            <Button color="redOutline" onClick={handleModal} loading={deleting}>
                Löschen
            </Button>
            <Modal
                handleModal={handleModal}
                isActive={isActive}
                handleSubmit={handleSubmit}
                submitButton="Projekt löschen"
                headline="Projekt Löschen?"
            >
                <TextWrapper>
                    {project.tenders.length > 0
                        ? "Bitte lösche zuerst die Ausschreibungen in diesem Projekt!"
                        : `Möchtest du das Projekt ${project.name} Wirklich Löschen?`}
                </TextWrapper>
            </Modal>
        </>
    );
};

export default ProjectDelete;
