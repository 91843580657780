import React, { useEffect } from "react";

import { useParams } from "@gatsbyjs/reach-router";

import styled from "@emotion/styled";

import DatePicker from "../../../../../shared/forms/DatePicker";
import FormError from "../../../../../shared/forms/FormError";
import Checkbox from "../../../../../shared/forms/NewCheckbox";
import NewInput from "../../../../../shared/forms/NewInput";
import NewSelect from "../../../../../shared/forms/NewSelect";
import { navigate } from "../../../../../shared/LinkComponent";
import Message from "../../../../../shared/Message";
import Button from "../../../../../shared/NewForms/Button";
import NewHeadline from "../../../../../shared/NewHeadline";
import useSnackbar from "../../../../../shared/Snackbar/store";
import CraftPicker from "../../../components/CraftPicker";
import DistributionListSelector from "../../../components/DistributionListSelector";
import FileHandler from "../../../components/FileHandler";
import ValidationErrors from "../../../components/ValidationErrors";
import useProjectsStore from "../../Projects/store";
import useStore from "./store";

const SubmitButtonsContainer = styled.div`
    display: flex;
    flex-direction: column;
    ${({ theme }) => theme.breakpoints.from.small.css`
          flex-direction: row;
     `}

    gap: 20px;
    justify-content: center;
    margin-top: 50px;
`;

const mapToSelectOptions = (collection) =>
    collection.map((item) => ({
        text: item.name,
        value: item.id,
    }));

const Form = ({ tender }) => {
    const {
        data,
        setData,
        setDataField,
        saving,
        truncateData,
        save,
        errors,
        hasErrorAt,
        addError,
        clearError,
        setErrors,
        deleting,
        deleteFile,
        setFileUploadName,
        addFileUploads,
        validationErrors,
    } = useStore();
    const { projects, fetchProjects } = useProjectsStore();
    const { notify } = useSnackbar();
    const { projectId } = useParams();

    useEffect(() => {
        if (tender) {
            setData({
                id: tender.id,
                projectId: tender.project.id,
                stateId: tender.state.id,
                name: tender.name,
                description: tender.description,
                estimatedStartAt: tender.estimated_start_at,
                estimatedEndAt: tender.estimated_end_at,
                applicationDeadlineAt: tender.application_deadline_at,
                crafts: tender.crafts,
                state: tender.state,
                files: tender.files,
                fileUploads: [],
                fileUploadNames: [],
                notify: false,
                notificationEmails: [],
            });
        } else {
            truncateData(projectId);
        }
    }, [tender]);

    useEffect(() => {
        if (data.files.length > 0 || data.fileUploads.length > 0) {
            clearError("files");
        }
    }, [data.files, data.fileUploads]);

    const onChange = (event) => {
        setDataField(event.target.name, event.target.value);
    };

    const selectEmails = (emails) => {
        const emailsToAdd = emails.filter(
            (email) => !data.notificationEmails.includes(email)
        );
        setDataField("notificationEmails", [
            ...data.notificationEmails,
            ...emailsToAdd,
        ]);
    };

    const deselectEmails = (emails) => {
        setDataField(
            "notificationEmails",
            data.notificationEmails.filter((email) => !emails.includes(email))
        );
    };

    const onSubmit = (event, publish = false) => {
        event.preventDefault();

        let localErrors = [];
        Object.keys(data).forEach((key) => {
            if (!["id", "lat", "long", "projectId", "stateId"].includes(key)) {
                if (data[key] === "" && !hasErrorAt(key)) {
                    localErrors.push(key);
                }
            }
        });

        if (
            (publish || (tender && tender.state.name === "active")) &&
            data.files.length === 0 &&
            data.fileUploads.length === 0
        ) {
            localErrors.push("files");
        }

        setErrors(localErrors);

        if (errors.length === 0 && localErrors.length === 0) {
            save(() => {
                notify(
                    "Erfolgreich",
                    "Die Ausschreibung wurde erfolgreich gespeichert."
                );
                fetchProjects();
                void navigate(
                    `/ausschreibungsportal/admin/projekte/${data.projectId}`
                );
            }, publish);
        } else {
            let id = [...errors, ...localErrors][0];
            if (id === "street" || id === "zipcode" || id === "city") {
                id = "location";
            }
            const element = document.getElementById(id);
            if (element) {
                element.scrollIntoView();
                element.focus();
            }
        }
    };

    return (
        <form onSubmit={onSubmit}>
            <NewInput
                name="name"
                placeholder="Name"
                value={data.name}
                onChange={onChange}
                onError={() => addError("name")}
                onErrorSolved={() => clearError("name")}
                forceError={hasErrorAt("name")}
                withField
                required
            />
            <NewInput
                name="description"
                placeholder="Beschreibung"
                value={data.description}
                type="textarea"
                onChange={onChange}
                onError={() => addError("description")}
                onErrorSolved={() => clearError("description")}
                forceError={hasErrorAt("description")}
                withField
                required
            />
            <NewSelect
                placeholder="Projekt"
                name="projectId"
                onChange={onChange}
                value={data.projectId}
                options={mapToSelectOptions(projects)}
                withField
            />

            <DatePicker
                name="estimatedStartAt"
                label="Startdatum"
                value={data.estimatedStartAt ?? new Date()}
                onChange={(value) => setDataField("estimatedStartAt", value)}
            />
            {hasErrorAt("estimatedStartAt") && (
                <FormError message="Gib bitte ein Startdatum ein." />
            )}

            <DatePicker
                name="estimatedEndAt"
                label="Enddatum"
                value={data.estimatedEndAt ?? new Date()}
                onChange={(value) => setDataField("estimatedEndAt", value)}
            />
            {hasErrorAt("estimatedEndAt") && (
                <FormError message="Gib bitte ein Enddatum ein." />
            )}

            <DatePicker
                name="applicationDeadlineAt"
                label="Abgabefrist"
                value={data.applicationDeadlineAt ?? new Date()}
                onChange={(value) =>
                    setDataField("applicationDeadlineAt", value)
                }
            />
            {hasErrorAt("applicationDeadlineAt") && (
                <FormError message="Gib bitte ein Abgabefrist ein." />
            )}

            <CraftPicker
                selectedCrafts={data.crafts}
                craftsTitle="Zugewiesene Gewerke"
                addNewTitle="Gewerke zuweisen"
                onChange={(crafts) => setDataField("crafts", crafts)}
            />

            <FileHandler
                id="files"
                data={data}
                deleting={deleting}
                deleteFile={deleteFile}
                setFileUploadName={setFileUploadName}
                addFileUploads={addFileUploads}
            />

            {hasErrorAt("files") && (
                <FormError message="Lade bitte mindestens eine Datei hoch." />
            )}

            <NewHeadline>Verteiler</NewHeadline>

            {data.state.name === "draft" && (
                <Message color="grey">
                    Diese Ausschreibung befindet sich im <strong>Draft</strong>{" "}
                    Status. Die E-Mails werden nur an den ausgewählten Verteiler
                    geschickt, wenn diese Ausschreibung veröffentlicht wird.
                </Message>
            )}

            <DistributionListSelector
                selectedEmails={data.notificationEmails}
                onSelectEmails={selectEmails}
                onDeselectEmails={deselectEmails}
            />

            {data.id && data.state.name === "active" && (
                <>
                    <NewHeadline>Benachrichtigung</NewHeadline>
                    <Checkbox
                        label="Änderungsbenachrichtigung versenden"
                        checked={data.notify}
                        onClick={() => setDataField("notify", !data.notify)}
                    />
                </>
            )}

            <ValidationErrors error={validationErrors} />

            <SubmitButtonsContainer>
                <Button centered color="redOutline" disabled={saving}>
                    Speichern
                </Button>

                {data.state.name === "draft" && (
                    <Button
                        disabled={saving}
                        type="button"
                        centered
                        color="red"
                        onClick={(event) => onSubmit(event, true)}
                    >
                        {data.id
                            ? "Veröffentlichen"
                            : "Speichern und veröffentlichen"}
                    </Button>
                )}
            </SubmitButtonsContainer>
        </form>
    );
};

export default Form;
