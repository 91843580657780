import React from "react";

import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { toReadableDate } from "../../../../../utils/DateHelpers";
import { formatNumber, roundNumber } from "../../../../../utils/NumberHelpers";
import { truncateString } from "../../../../../utils/StringHelpers";
import Columns from "../../../../shared/Columns";
import LinkComponent from "../../../../shared/LinkComponent";
import NewHeadline from "../../../../shared/NewHeadline";
import TenderState from "../../components/TenderState";
import CraftPills from "../CraftPills";

const Vertical = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
`;

const LinkItem = styled.a`
    color: ${({ theme }) => theme.colors.red.toString()};
`;

const ActionRow = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`;

export const dispayDistance = (distance) => {
    if (distance < 1) distance = 0;
    return formatNumber(roundNumber(distance, 0));
};

const TenderItem = ({
    tender,
    truncateDescription = true,
    showContact = false,
    showOwner = false,
    showLink = false,
    showProjectDescription = false,
}) => {
    return (
        <>
            <NewHeadline color="blue">{tender.name}</NewHeadline>
            {showProjectDescription && tender.project && (
                <p>{tender.project.description}</p>
            )}

            <p>
                {truncateDescription
                    ? truncateString(tender.description)
                    : tender.description}
            </p>
            <Columns>
                <Columns.Column>
                    <Vertical>
                        <NewHeadline lookLike="4" color="blue">
                            Projekt:
                        </NewHeadline>
                        <span
                            css={css`
                                padding-bottom: 20px;
                            `}
                        >
                            {tender.project.name}
                            <br />
                            Projektstart:{" "}
                            {toReadableDate(tender.project.estimated_start_at)}
                        </span>
                    </Vertical>
                </Columns.Column>
                <Columns.Column>
                    <Vertical>
                        <NewHeadline color="blue" lookLike={4}>
                            Status:
                        </NewHeadline>
                        <TenderState state={tender.state} />
                        <span>Geschätzter Zeitraum: </span>
                        <span>
                            {toReadableDate(tender.estimated_start_at)} -{" "}
                            {toReadableDate(tender.estimated_end_at)}
                        </span>
                        <span>
                            Abgabefrist:{" "}
                            {toReadableDate(tender.application_deadline_at)}
                        </span>
                    </Vertical>
                </Columns.Column>
                <Columns.Column>
                    <Vertical>
                        <NewHeadline color="blue" lookLike={4}>
                            Adresse:
                        </NewHeadline>
                        <span>
                            {tender.project.street}{" "}
                            {tender.project.street_number}
                        </span>
                        <span>
                            {tender.project.zipcode} {tender.project.city}
                        </span>
                        <span>
                            Entfernung:{" "}
                            {dispayDistance(tender.distance_to_auth_user_km)} km
                        </span>
                    </Vertical>
                </Columns.Column>

                {showOwner && tender?.project?.owner_name && (
                    <Columns.Column>
                        <Vertical>
                            <NewHeadline color="blue" lookLike={4}>
                                Bauherr:
                            </NewHeadline>
                            <span>{tender.project.owner_name}</span>
                            <span>
                                {tender.project.owner_street}{" "}
                                {tender.project.owner_street_number}
                            </span>
                            <span>
                                {tender.project.owner_zipcode}{" "}
                                {tender.project.owner_city}
                            </span>
                        </Vertical>
                    </Columns.Column>
                )}
            </Columns>
            {showContact && tender.project && (
                <Columns>
                    <Columns.Column>
                        <Vertical>
                            <NewHeadline color="blue" lookLike={4}>
                                Kontakt:
                            </NewHeadline>
                            <span>
                                {tender.project.contact_salutation}{" "}
                                {tender.project.contact_firstname}{" "}
                                {tender.project.contact_lastname}
                            </span>
                            <span>
                                Email:{" "}
                                <LinkItem
                                    href={`mailto:${tender.project.contact_email}`}
                                >
                                    {tender.project.contact_email}
                                </LinkItem>
                            </span>
                            <span>Tel: {tender.project.contact_phone}</span>
                        </Vertical>
                    </Columns.Column>
                </Columns>
            )}
            <ActionRow>
                <div>
                    <NewHeadline lookLike="4" color="blue">
                        Gewerke:
                    </NewHeadline>
                    <CraftPills crafts={tender.crafts} />
                </div>
                <div>
                    {showLink && (
                        <LinkComponent
                            link={{
                                href: `/ausschreibungsportal/${tender.id}`,
                                text: "Zur Ausschreibung",
                            }}
                            color="red"
                            button
                        />
                    )}
                </div>
            </ActionRow>
        </>
    );
};

export default TenderItem;
