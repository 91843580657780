import React, { useEffect } from "react";

import { css, useTheme } from "@emotion/react";

import Container from "../../../../shared/Container";
import LoadingIndicator from "../../../../shared/LoadingIndicator";
import Headline from "../../../../shared/NewHeadline";
import { TabbedControlled } from "../../../../shared/Tabs";
import Box from "../../components/Box";
import useStore from "../store";
import AccountCertificateForm from "./Forms/AccountCertificateForm";
import AccountCraftsForm from "./Forms/AccountCraftsForm";
import AccountForm from "./Forms/AccountForm";

const Profile = () => {
    const { loading, account, fetchAuthAccount, accountCrafts } = useStore();
    const theme = useTheme();

    useEffect(() => {
        if (account === undefined) {
            fetchAuthAccount();
        }
    }, []);

    if (loading) {
        return <LoadingIndicator />;
    }

    if (!account) {
        return null;
    }

    const getTabs = () => {
        const tabs = {
            controls: {
                profile: "Profil",
                crafts: "Gewerke",
            },
            components: {
                profile: <AccountForm account={account} />,
                crafts: <AccountCraftsForm />,
            },
        };

        // The certificate is only necessary for the following crafts.
        // Only show the Tab if the user has these crafts assigned.
        const showCertificate = accountCrafts.some((craft) => {
            const name = craft.name.toLowerCase();
            return name === "tiefbau" || name === "betonbau";
        });

        if (showCertificate === true) {
            tabs["controls"]["certificate"] = "WHG Zertifikat";
            tabs["components"]["certificate"] = <AccountCertificateForm />;
        }

        return tabs;
    };

    const newStyles = css`
        font-family: var(--font-family-vito);
        color: ${theme.colors.blue.toString()};
    `;

    return (
        <Container slim css={newStyles}>
            <Headline lookLike={2}>Profil für Bauausschreibungen</Headline>
            <Box>
                <TabbedControlled firstTabIsH1 tabs={getTabs().controls}>
                    {getTabs().components}
                </TabbedControlled>
            </Box>
        </Container>
    );
};

export default Profile;
