import React from "react";

import { css, useTheme } from "@emotion/react";

import Columns from "../../../../shared/Columns";
import Container from "../../../../shared/Container";
import Headline from "../../../../shared/NewHeadline";
import Box from "../../components/Box";
import TermsOfServiceForm from "./Forms/TermsOfServiceForm";

const TermsOfService = () => {
    const theme = useTheme();

    const newStyles = css`
        font-family: var(--font-family-vito);
        color: ${theme.colors.blue.toString()};
    `;

    return (
        <Container slim css={newStyles}>
            <Columns>
                <Columns.Column>
                    <Headline>Vertragsbedingungen</Headline>
                    <p>
                        Du musst die folgenden Vertragsbedingungen akzeptieren,
                        um das Ausschreibungsportal benutzen zu können.
                    </p>
                </Columns.Column>
            </Columns>

            <Box>
                <TermsOfServiceForm />
            </Box>
        </Container>
    );
};

export default TermsOfService;
