import React, { useEffect } from "react";

import { useDebounce } from "use-debounce";

import { toReadableDate } from "../../../../../utils/DateHelpers";
import Columns from "../../../../shared/Columns";
import NewCheckbox from "../../../../shared/forms/NewCheckbox";
import Input from "../../../../shared/forms/NewInput";
import Link from "../../../../shared/LinkComponent";
import LoadingIndicator from "../../../../shared/LoadingIndicator";
import Message from "../../../../shared/Message";
import Button from "../../../../shared/NewForms/Button";
import NewHeadline from "../../../../shared/NewHeadline";
import useSnackbar from "../../../../shared/Snackbar/store";
import Box from "../../components/Box";
import CraftPills from "../../components/CraftPills";
import DownloadLink from "../../components/DownloadLink";
import useStore from "./store";

const RegisteredCompanies = () => {
    const {
        loading,
        fetchAccounts,
        accounts,
        searchTerm,
        setSearchTerm,
        setPage,
        filterActiveCompanies,
        setFilterActiveCompanies,
    } = useStore();
    const [debouncedFilter] = useDebounce(searchTerm, 500);

    useEffect(() => {
        setPage(1);
        fetchAccounts(searchTerm);
    }, [debouncedFilter]);

    const handleActiveFilter = () => {
        setFilterActiveCompanies(!filterActiveCompanies);
        fetchAccounts();
    };

    return (
        <>
            <Columns>
                <Columns.Column>
                    <NewHeadline>Registrierte Unternehmen</NewHeadline>
                </Columns.Column>
                <Columns.Column>
                    <Input
                        name="name"
                        value={searchTerm}
                        onChange={(event) => setSearchTerm(event.target.value)}
                        placeholder="Suche (Firmenname)"
                        withField
                    />
                    <NewCheckbox
                        checked={filterActiveCompanies}
                        label="Nur aktive Unternehmen anzeigen"
                        onChange={handleActiveFilter}
                    />
                </Columns.Column>
            </Columns>
            {loading && <LoadingIndicator />}
            {!loading && accounts.length === 0 && (
                <Columns>
                    <Columns.Column>
                        <Message color="grey">
                            Es wurden keine Firmen für den Suchbegriff &bdquo;
                            {searchTerm}&ldquo; gefunden.
                        </Message>
                    </Columns.Column>
                </Columns>
            )}
            {!loading && accounts.length > 0 && (
                <Columns>
                    <Columns.Column>
                        {accounts.map((account) => (
                            <Account key={account.id} account={account} />
                        ))}
                    </Columns.Column>
                </Columns>
            )}

            <Columns>
                <Columns.Column>
                    <LoadMore />
                </Columns.Column>
            </Columns>
        </>
    );
};

const Account = ({ account }) => {
    const { accounts, setAccounts } = useStore();
    const { confirm } = useSnackbar();
    const { updateAccountActive } = useStore();

    const toggleExpanded = (account) => {
        const updatedAccounts = accounts.map((acc) => {
            if (acc.id === account.id) {
                acc.isExpanded = !acc.isExpanded;
            }
            return acc;
        });
        setAccounts(updatedAccounts);
    };

    const handleActiveToggle = (account, active) => {
        let title = "Account inaktiv setzen";
        let text = `Möchtest du den Account '${account.company}' inaktiv setzen?`;
        if (active === true) {
            title = "Account aktiv setzen";
            text = `Möchtest du den Account '${account.company}' wieder aktiv setzen?`;
        }
        confirm(title, text).then((confirm) => {
            if (confirm === true) {
                updateAccountActive(account.id, active);
            }
        });
    };

    if (accounts.length < 1) {
        return null;
    }

    return (
        <Box key={account.id}>
            <Columns>
                <Columns.Column>
                    <strong>{account.company}</strong>
                    <br />
                    <Button
                        extraSmall
                        color="redOutline"
                        onClick={() => toggleExpanded(account)}
                    >
                        {account.crafts.length} Gewerke{" "}
                        {account.isExpanded ? "schließen" : "anzeigen"}
                    </Button>
                </Columns.Column>
                <Columns.Column>
                    {account.salutation} {account.firstname} {account.lastname}
                    <br />
                    <Link
                        link={{
                            href: `mailto:${account.email}`,
                            text: account.email,
                        }}
                    />
                </Columns.Column>
                <Columns.Column>
                    {account.street} {account.street_number} <br />
                    {account.zipcode} {account.city}
                </Columns.Column>

                <Columns.Column>
                    Erstellt: {toReadableDate(account.created_at)} <br />
                    <ActiveToggleButton
                        account={account}
                        isActive={account.is_active}
                        onClick={handleActiveToggle}
                    />
                    <br />
                    {account.certificate && (
                        <DownloadLink
                            file={account.certificate}
                            filename="WHG-Zertifikat"
                        />
                    )}
                </Columns.Column>
            </Columns>

            {account.isExpanded && (
                <Columns>
                    <Columns.Column>
                        <CraftPills crafts={account.crafts} />
                    </Columns.Column>
                </Columns>
            )}
        </Box>
    );
};

const ActiveToggleButton = ({ isActive, account, onClick }) => {
    if (isActive) {
        return (
            <Button
                small
                color="redOutline"
                onClick={() => onClick(account, false)}
            >
                Deaktivieren
            </Button>
        );
    }

    return (
        <Button small onClick={() => onClick(account, true)}>
            Aktivieren
        </Button>
    );
};

const LoadMore = () => {
    const { loadingMore, fetchMoreAccounts, accountMeta, setPage } = useStore();

    const onClick = () => {
        setPage(accountMeta.current_page + 1 ?? 1);
        fetchMoreAccounts();
    };

    if (loadingMore) {
        return <LoadingIndicator />;
    }

    return (
        <>
            {accountMeta && accountMeta.current_page < accountMeta.last_page && (
                <Button color="redOutline" onClick={onClick} centered>
                    Mehr anzeigen
                </Button>
            )}
        </>
    );
};

export default RegisteredCompanies;
