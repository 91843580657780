import React from "react";

import { css } from "@emotion/react";
import styled from "@emotion/styled";

import Columns from "../../../../shared/Columns";
import NewInput from "../../../../shared/forms/NewInput";
import NewHeadline from "../../../../shared/NewHeadline";
import useSnackbar from "../../../../shared/Snackbar/store";
import FileList from "../FileList";

const FilenameField = styled.div`
    padding: 9px 0;
    margin-top: 5px;
    border-radius: 4px;
`;

const Container = styled.div`
    padding-top: 100px;
    margin: -50px 0 50px 0;
`;

const fileNameInputColumnStyles = css`
    margin-top: -5px;
`;

const Text = styled.p`
    padding-top: 20px;
    font-weight: 900;
`;

const FileHandler = ({
    data,
    deleting,
    deleteFile,
    setFileUploadName,
    addFileUploads,
    ...props
}) => {
    const { confirm } = useSnackbar();
    const onFileChange = (event) => {
        addFileUploads(event.target.files);
    };

    const onDelete = (file) => {
        confirm(
            "Datei löschen?",
            "Möchtest du diese Datei wirklich löschen?"
        ).then((confirmed) => {
            if (confirmed) {
                deleteFile(file);
            }
        });
    };

    return (
        <Container {...props}>
            <NewHeadline>Dateien verwalten</NewHeadline>
            <FileList
                files={data.files}
                onDelete={onDelete}
                deleting={deleting}
            />

            <Text>Dateien hochladen</Text>
            {data.fileUploadNames.map((fileUploadName, index) => {
                return (
                    <Columns key={index}>
                        <Columns.Column desktop="two-fifths">
                            <FilenameField>
                                {Array.from(data.fileUploads)[index].name}
                            </FilenameField>
                        </Columns.Column>
                        <Columns.Column
                            desktop="three-fifths"
                            css={fileNameInputColumnStyles}
                        >
                            <NewInput
                                name="filename"
                                placeholder="Dateiname"
                                value={fileUploadName}
                                onChange={(event) =>
                                    setFileUploadName(index, event.target.value)
                                }
                                required
                            />
                        </Columns.Column>
                    </Columns>
                );
            })}
            <input type="file" multiple onChange={onFileChange} />
        </Container>
    );
};

export default FileHandler;
