import React, { useState } from "react";

import styled from "@emotion/styled";

import Columns from "../../../../shared/Columns";
import { navigate } from "../../../../shared/LinkComponent";
import Button from "../../../../shared/NewForms/Button";
import useSnackbar from "../../../../shared/Snackbar/store";
import useStore from "../../Admin/DistributionLists/store";
import Box from "../Box";

const BoxContent = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const BoxContentExtended = styled.div`
    margin-top: 20px;
`;

const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    ${({ theme }) => theme.breakpoints.from.small.css`
       flex-direction: row;
    `}
    gap: 20px;
`;

const ListName = styled.div`
    font-weight: 500;
`;

const DistributionListItem = ({ list }) => {
    const { loading, deleteListById } = useStore();
    const [isExtended, setIsExtended] = useState(false);
    const { notify } = useSnackbar();

    const onDeleteSucces = () => {
        void notify(
            "Erfolgreich",
            "Der E-Mail Verteiler wurde erfolgreich gelöscht"
        );
    };

    return (
        <Box key={list.id}>
            <BoxContent>
                <ListName>
                    {list.name} ({list.emails.length})
                </ListName>
                <ButtonWrapper>
                    <Button
                        color="blueOutline"
                        onClick={() => setIsExtended(!isExtended)}
                    >
                        {isExtended ? "Weniger" : "Alle"} Anzeigen
                    </Button>
                    <Button
                        color="blueOutline"
                        onClick={() =>
                            navigate(
                                `/ausschreibungsportal/admin/verteiler/${list.id}/bearbeiten`
                            )
                        }
                    >
                        Bearbeiten
                    </Button>
                    {list.emails.length === 0 && (
                        <Button
                            color="redOutline"
                            disabled={loading}
                            onClick={() =>
                                deleteListById(list.id, onDeleteSucces)
                            }
                        >
                            Löschen
                        </Button>
                    )}
                </ButtonWrapper>
            </BoxContent>
            {isExtended && (
                <BoxContentExtended>
                    <Columns>
                        {list.emails.map((email) => (
                            <Columns.Column
                                key={email.id}
                                desktop={"one-third"}
                                tabled={"half"}
                                mobile={"full"}
                            >
                                {email.email}
                            </Columns.Column>
                        ))}
                    </Columns>
                </BoxContentExtended>
            )}
        </Box>
    );
};

export default DistributionListItem;
