import React from "react";

import { Permission, useHoyerAcl } from "../../../../../../hooks/useHoyerAcl";
import Button from "../../../../../shared/NewForms/Button";
import useSnackbar from "../../../../../shared/Snackbar/store";
import useProjectStore from "../../Projects/store";
import useStore from "../Form/store";

const TenderDelete = ({ tender }) => {
    const { notify, confirm } = useSnackbar();
    const { can } = useHoyerAcl();
    const [deleteTender, deleting] = useStore((state) => [
        state.deleteTender,
        state.deleting,
    ]);

    const [fetchProjects] = useProjectStore((state) => [state.fetchProjects]);

    const handleSubmit = () => {
        confirm(
            "Ausschreibung löschen",
            `Möchtest du die Ausschreibung "${tender.name}" wirklich löschen?`
        ).then((confirmed) => {
            if (confirmed) {
                deleteTender(
                    tender.id,
                    () => {
                        void notify(
                            "Erfolgreich gelöscht",
                            `Die Ausschreibung wurde erfolgreich gelöscht.`
                        );
                        fetchProjects();
                    },
                    (message) => {
                        void notify("Fehler", message, "error");
                    }
                );
            }
        });
    };

    if (!can(Permission.AUSSCHREIBUNG_MANAGE)) {
        return null;
    }

    return (
        <Button
            color="redOutline"
            extraSmall
            onClick={handleSubmit}
            loading={deleting}
        >
            Löschen
        </Button>
    );
};

export default TenderDelete;
