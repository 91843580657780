import React, { useEffect } from "react";

import styled from "@emotion/styled";

import Input from "../../../../../shared/forms/NewInput";
import { navigate } from "../../../../../shared/LinkComponent";
import Button from "../../../../../shared/NewForms/Button";
import Headline from "../../../../../shared/NewHeadline";
import useNotificationStore from "../../../../../shared/Notification/store";
import useSnackbar from "../../../../../shared/Snackbar/store";
import Box from "../../../components/Box";
import useDistributionListStore from "../store";
import useStore from "./store";

const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    ${({ theme }) => theme.breakpoints.from.small.css`
          flex-direction: row;
          align-items: center;
    `}
    gap: 20px;
`;

const InputButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    ${({ theme }) => theme.breakpoints.from.small.css`
          flex-direction: row;
     `}
    justify-content: space-between;
    align-items: center;
    gap: 20px;
`;

const ItemContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px 0;
`;

const InputWrapper = styled.div`
    flex-grow: 1;
`;

const ButtonWrapper = styled.div`
    max-width: 150px;
    ${({ theme }) => theme.breakpoints.from.small.css`
         max-width: unset;
    `}
`;

const ListContainer = styled.div`
    margin-top: 20px;
`;

const Form = ({ list }) => {
    const {
        saving,
        data,
        errors,
        setData,
        setErrors,
        addError,
        clearError,
        hasErrorAt,
        setName,
        setEmail,
        deleteEmail,
        createEmail,
        truncateData,
        save,
    } = useStore();
    const { fetchLists } = useDistributionListStore();
    const { notify } = useNotificationStore();
    const { confirm } = useSnackbar();

    useEffect(() => {
        if (list) {
            setData({
                id: list.id,
                name: list.name,
                email: "",
                emails: list.emails.map((email) => ({
                    id: email.id,
                    email: email.email,
                })),
            });
        } else {
            truncateData();
        }
    }, [list]);

    const onChangeName = (event) => {
        setName(event.target.value);
    };

    const onDeleteEmail = (email) => {
        confirm(
            "E-Mail löschen?",
            "Möchtest du diese E-Mail wirklich löschen?"
        ).then((confirmed) => {
            if (confirmed) {
                deleteEmail(email, () => {
                    fetchLists();
                    notify("Die E-Mail wurde gelöscht.");
                });
            }
        });
    };

    const onButtonClick = () => {
        if (data.email === "") {
            addError("email");
        }
        if (data.email !== "" || !hasErrorAt("email")) {
            createEmail(() => {
                fetchLists();
                notify("Die E-Mail wurde erfolgreich hinzugefügt");
            });
        }
    };

    const onSubmit = (event) => {
        event.preventDefault();

        let localErrors = [];

        if (data["name"] === "" && !hasErrorAt("name")) {
            localErrors.push("name");
        }

        setErrors(localErrors);

        if (errors.length === 0 && localErrors.length === 0) {
            save((id) => {
                notify("Der Verteiler wurde erfolgreich gespeichert");
                fetchLists();
                if (!data.id) {
                    void navigate(
                        `/ausschreibungsportal/admin/verteiler/${id}/bearbeiten`
                    );
                }
            });
        }
    };

    return (
        <>
            <form onSubmit={onSubmit}>
                <FormContainer>
                    <InputWrapper>
                        <Input
                            name="name"
                            placeholder="Name des Verteilers"
                            value={data.name}
                            onChange={onChangeName}
                            onError={() => addError("name")}
                            onErrorSolved={() => clearError("name")}
                            forceError={hasErrorAt("name")}
                            withField
                            required
                        />
                    </InputWrapper>
                    <Button color="redOutline" disabled={saving}>
                        Speichern
                    </Button>
                </FormContainer>
            </form>
            {list && (
                <ListContainer>
                    <Headline lookLike="3">E-Mails im Verteiler</Headline>
                    {data.emails.length === 0 && (
                        <div>
                            Es befinden sich noch keine E-Mails im Verteiler
                        </div>
                    )}
                    <Box>
                        <InputButtonContainer>
                            <InputWrapper>
                                <Input
                                    name={"email"}
                                    placeholder="E-Mail"
                                    value={data.email}
                                    onChange={(event) =>
                                        setEmail(event.target.value)
                                    }
                                    onError={() => addError("email")}
                                    onErrorSolved={() => clearError("email")}
                                    forceError={hasErrorAt("email")}
                                    withField
                                    required
                                    validation="email"
                                />
                            </InputWrapper>
                            <Button
                                color="redOutline"
                                type="button"
                                onClick={onButtonClick}
                                disabled={saving}
                            >
                                Hinzufügen
                            </Button>
                        </InputButtonContainer>
                    </Box>
                    {data.emails.map((email, index) => (
                        <ItemContainer key={index}>
                            {email.email}
                            <ButtonWrapper>
                                <Button
                                    type="button"
                                    onClick={() => onDeleteEmail(email)}
                                    disabled={saving}
                                >
                                    Löschen
                                </Button>
                            </ButtonWrapper>
                        </ItemContainer>
                    ))}
                </ListContainer>
            )}
        </>
    );
};

export default Form;
