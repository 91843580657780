export const europeIsh = {
    north: 58.4037698,
    east: 24.5315143,
    south: 36.667871,
    west: -10.292125,
};

export const germany = {
    north: 55.099161,
    east: 15.0419319,
    south: 47.2701114,
    west: 5.8663153,
};
