import create from "zustand";

import { apiFetchProtected } from "../../../../../../apiBindings";
import createFieldSlices from "../../../../../../utils/store/fields";
import createFormErrorSlices from "../../../../../../utils/store/formErrors";

const baseUrl = "/call-for-tenders/admin/distribution-lists";

const initialData = {
    id: null,
    name: "",
    emails: [],
    email: "",
};

const useStore = create((set, get) => ({
    ...createFormErrorSlices(set, get),
    ...createFieldSlices("saving", false, set),

    data: initialData,
    setData: (data) => set({ data }),
    setName: (name) => {
        const data = get().data;
        set({ data: { ...data, name } });
    },
    setEmail: (email) => {
        const data = get().data;
        set({ data: { ...data, email } });
    },
    save: (onSave) => {
        set({ saving: true });

        const data = get().data;

        const payload = {
            name: data.name,
        };

        apiFetchProtected(`${baseUrl}${data.id ? `/${data.id}` : ""}`, {
            method: "POST",
            body: JSON.stringify(payload),
        }).then((response) => {
            onSave(response.data.id);
            set({
                lists: response.data,
                saving: false,
            });
        });
    },
    createEmail: (onCreate) => {
        set({ saving: true });

        const data = get().data;

        const payload = {
            email: data.email,
        };

        apiFetchProtected(`${baseUrl}/${data.id}/emails`, {
            method: "POST",
            body: JSON.stringify(payload),
        }).then((response) => {
            onCreate();
            set({
                lists: response.data,
                saving: false,
            });
        });
    },
    deleteEmail: (email, onDelete) => {
        set({ saving: true });

        const data = get().data;

        apiFetchProtected(`${baseUrl}/${data.id}/emails/${email.id}`, {
            method: "DELETE",
        }).then((response) => {
            onDelete();
            set({
                lists: response.data,
                saving: false,
            });
        });
    },
    truncateData: () => set({ data: initialData }),
}));

export default useStore;
