import create from "zustand";

import { apiFetchProtected } from "../../../../../../../apiBindings";

const initialData = {
    terms_of_service: [],
};

const useStore = create((set, get) => ({
    data: initialData,
    saving: false,
    saved: false,
    setData: (data) => {
        set({ data });
    },
    setDataField: (field, value) => {
        const data = get().data;
        data[field] = value;
        set({ data });
    },
    setSaving: (saving) => {
        set({ saving });
    },
    setSaved: (saved) => {
        set({ saved });
    },
    truncateData: () => {
        set({ data: initialData, saved: false });
    },
    save: (onSaved, onError) => {
        const data = get().data;
        const isValid = data.terms_of_service.every(
            (term) => term.checked === true
        );
        if (!isValid && onError) {
            onError(
                "Du musst alle Vertragsbedingungen akzeptieren, um fortfahren zu können."
            );
            return;
        }
        set({ saving: true });
        const payload = {
            terms_of_service: data.terms_of_service.map((terms) => {
                return {
                    id: terms.id,
                    checked: terms.checked,
                };
            }),
        };

        apiFetchProtected("/call-for-tenders/account/terms-of-service", {
            method: "POST",
            body: JSON.stringify(payload),
        })
            .then((response) => {
                set({ saved: true });
                if (onSaved) {
                    onSaved(response.data);
                }
            })
            .finally(() => set({ saving: false }));
    },
}));

export default useStore;
