import create from "zustand";

import {
    apiFetchProtected,
    errorAwareApiFetchProtected,
} from "../../../../../../../apiBindings";
import createFieldSlices from "../../../../../../../utils/store/fields";

const endpoint = "/call-for-tenders/account/certificate";

const useStore = create((set, get) => ({
    ...createFieldSlices("file", undefined, set),
    ...createFieldSlices("filename", "", set),

    loading: false,
    uploading: false,
    deleting: false,
    certificate: undefined,
    validationErrors: "",

    fetchCertificate: () => {
        set({ loading: true });
        apiFetchProtected(endpoint)
            .then((response) => {
                set({ certificate: response.data });
            })
            .finally(() => {
                set({ loading: false });
            });
    },

    uploadCertificate: (file, filename) => {
        set({ uploading: true, validationErrors: undefined });
        let data = new FormData();
        data.append("file", file);
        data.append("filename", filename);

        const options = {
            method: "POST",
            body: data,
        };

        errorAwareApiFetchProtected(endpoint, options, true)
            .then((response) => {
                if (response?.data) {
                    set({
                        certificate: response.data,
                        file: undefined,
                        filename: "",
                    });
                }
            })
            .catch((error) => {
                if (error.response.status === 422) {
                    set({ validationErrors: error.data });
                }
            })
            .finally(() => {
                set({ uploading: false });
            });
    },

    deleteCertificate: () => {
        set({ deleting: true });
        const certificate = get().certificate;
        const url = `${endpoint}/${certificate.id}`;

        errorAwareApiFetchProtected(url, {
            method: "DELETE",
        })
            .then(() => {
                set({ certificate: undefined });
            })
            .catch((error) => {
                if (error.response.status === 422) {
                    set({ validationErrors: error.data });
                }
            })
            .finally(() => {
                set({ deleting: false });
            });
    },
}));

export default useStore;
