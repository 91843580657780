import React, { useEffect, useMemo, useState } from "react";

import styled from "@emotion/styled";

import NewCheckbox from "../../../../../../../components/shared/forms/NewCheckbox";
import Margin from "../../../../../../../components/shared/Margin";
import Button from "../../../../../../../components/shared/NewForms/Button";
import Headline from "../../../../../../../components/shared/NewHeadline";
import { toReadableDate } from "../../../../../../../utils/DateHelpers";
import useSnackbar from "../../../../../../shared/Snackbar/store";
import DownloadLink from "../../../../components/DownloadLink";
import useDashboardStore from "../../../store";
import useStore from "./store";

const TermsOfServiceContainer = styled.div`
    margin-bottom: 5em;
`;

const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 2em;
`;

const InputContainer = styled.div`
    display: flex;
    justify-content: end;
`;

const TermsOfServiceBox = styled.div`
    height: 450px;
    padding: 1em;
    overflow-x: hidden;
    overflow-y: auto;
    border: 1px solid black;
`;

const TermsOfServiceFooter = styled.div`
    margin-top: 2em;
`;

const StyledButton = styled(Button)`
    margin-top: 1em;
`;

const TermsOfServiceForm = () => {
    const { account, fetchAuthAccount } = useDashboardStore();
    const { saving, setData, truncateData, data, save } = useStore();
    const { notify } = useSnackbar();

    useEffect(() => {
        if (account) {
            const terms = account.unaccepted_terms_of_service.map((terms) => {
                return {
                    ...terms,
                    checked: false, //Add custom state to array
                };
            });
            setData({ terms_of_service: terms });
        } else {
            truncateData();
        }
    }, [account]);

    const onCheckBoxChange = (event) => {
        const terms = data.terms_of_service.map((terms) => {
            if (event.target.name === terms.id) {
                terms.checked = event.target.value;
            }
            return terms;
        });
        setData({ terms_of_service: terms });
    };

    const onSubmit = (event) => {
        event.preventDefault();
        const onSuccess = () => {
            void notify(
                "Erfolgreich",
                "Deine akzeptierten Vertragsbedingungen wurden aktualisiert."
            );
            fetchAuthAccount();
        };
        const onError = (errorMessage) => {
            void notify("Fehler", errorMessage);
        };
        save(onSuccess, onError);
    };

    return (
        <form onSubmit={onSubmit}>
            {data.terms_of_service.map((terms) => (
                <TermsOfService
                    key={terms.id}
                    terms={terms}
                    onCheckBoxChange={onCheckBoxChange}
                />
            ))}

            <StyledButton
                centered
                type="submit"
                color="redOutline"
                disabled={saving}
                loading={saving}
            >
                Vertragsbedingungen Speichern
            </StyledButton>
        </form>
    );
};

export const TermsOfService = ({ terms, onCheckBoxChange }) => {
    const [isCheckboxDisabled, setIsCheckboxDisabled] = useState(true);

    //Make sure the user scrolls at least once to the bottom of the Terms, if the box is scrollable:
    useEffect(() => {
        const box = document.querySelector(`#tos-${terms.id}`);
        const isScrollable = box.clientHeight !== box.scrollHeight;
        setIsCheckboxDisabled(isScrollable);
    }, [terms]);

    const onScroll = (event) => {
        const isBottom =
            event.target.scrollHeight - event.target.scrollTop <=
            event.target.offsetHeight;

        if (isBottom) {
            setIsCheckboxDisabled(false);
        }
    };

    const termsTextFormatted = useMemo(() => {
        const termParts = terms.text.split("\n");
        return termParts.map((part, index) => (
            <>
                {part}
                {index < termParts.length - 1 && <br />}
            </>
        ));
    }, [terms.text]);

    return (
        <TermsOfServiceContainer>
            <HeaderContainer>
                <Headline lookLike={4}>{terms.title}</Headline>
                Aktualisiert am: {toReadableDate(terms.updated_at)}
            </HeaderContainer>
            <TermsOfServiceBox
                id={`tos-${terms.id}`}
                onScroll={(event) => onScroll(event, terms.id)}
            >
                <p>{termsTextFormatted}</p>
            </TermsOfServiceBox>
            <TermsOfServiceFooter>
                {terms.file && (
                    <InputContainer>
                        <Margin bottom={"20"}>
                            <DownloadLink file={terms.file} iconSize="16px" />
                        </Margin>
                    </InputContainer>
                )}

                <InputContainer>
                    <NewCheckbox
                        title={
                            isCheckboxDisabled
                                ? "Bitte scrolle die Bedingungen nach ganz unten, um akzeptieren zu können."
                                : ""
                        }
                        isDisabled={isCheckboxDisabled}
                        checked={terms.checked}
                        name={terms.id}
                        label={`Ich akzeptiere die oben stehenden Bedingungen (${terms.title})`}
                        onChange={onCheckBoxChange}
                    />
                </InputContainer>
            </TermsOfServiceFooter>
        </TermsOfServiceContainer>
    );
};

export default TermsOfServiceForm;
