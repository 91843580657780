/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import { useParams } from "@gatsbyjs/reach-router";

import LoadingIndicator from "../../../../../shared/LoadingIndicator";
import NewHeadline from "../../../../../shared/NewHeadline";
import Form from "../Form";
import useStore from "../store";

const Edit = () => {
    const { projects, loading, fetchProjects, getProjectById } = useStore();
    const { id } = useParams();

    const [project, setProject] = useState(null);

    useEffect(() => {
        if (id) {
            if (projects.length === 0) {
                fetchProjects();
            }
        }
    }, []);

    useEffect(() => {
        if (id && !loading) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            setProject(getProjectById(id));
        }
    }, [loading]);

    return loading ? (
        <LoadingIndicator />
    ) : (
        <>
            <NewHeadline>Projekt bearbeiten</NewHeadline>
            <Form project={project} />
        </>
    );
};

export default Edit;
