import React from "react";

import Columns from "../../../../../shared/Columns";
import Input from "../../../../../shared/forms/NewInput";
import Message from "../../../../../shared/Message";
import Button from "../../../../../shared/NewForms/Button";
import Headline from "../../../../../shared/NewHeadline";
import { CraftSelect } from "../../../components/CraftPicker";
import DownloadLink from "../../../components/DownloadLink";
import ValidationErrors from "../../../components/ValidationErrors";
import useStore from "../store";

const TermsOfServiceForm = ({
    onChange,
    onFileChange,
    onSubmit,
    isUpdateForm = false,
    file,
}) => {
    const { form, saving, validationErrors } = useStore();

    return (
        <form onSubmit={onSubmit}>
            <Columns>
                <Columns.Column>
                    <Input
                        name="title"
                        placeholder="Titel"
                        value={form.title}
                        withField
                        required
                        onChange={onChange}
                    />
                </Columns.Column>
            </Columns>
            <Columns>
                <Columns.Column>
                    <Input
                        name="text"
                        placeholder="Vertragsbedingungen (Text)"
                        value={form.text}
                        type="textarea"
                        withField
                        required
                        onChange={onChange}
                    />
                </Columns.Column>
            </Columns>
            <Columns>
                <Columns.Column>
                    <Headline lookLike={4} color="blue">
                        Geweksspezifische Vertragsbedingungen
                    </Headline>
                    <CraftSelect
                        name="craft_id"
                        value={form.craft_id}
                        onChange={onChange}
                    />
                    <Message color="grey">
                        Wenn ein Gewerk ausgewählt wird, gelten die
                        Vertragsbedingungen nur für jene, die das Gewerk
                        zugewiesen haben. Wenn man nichts auswählt, gelten die
                        Bedingungen für jeden.
                    </Message>
                </Columns.Column>
            </Columns>
            <Columns>
                <Columns.Column>
                    <Headline lookLike={4} color="blue">
                        Vertragsbedingungen als .pdf-Datei hochladen:
                    </Headline>
                </Columns.Column>
            </Columns>
            <Columns>
                <Columns.Column>
                    <input
                        onChange={onFileChange}
                        name="file"
                        accept="application/pdf"
                        type="file"
                    />
                </Columns.Column>
                {file && (
                    <Columns.Column>
                        <p>Aktuell hinterlegte Datei:</p>
                        <DownloadLink
                            file={file}
                            filename={`Download ${file.filename}`}
                        />
                    </Columns.Column>
                )}
            </Columns>

            {isUpdateForm && (
                <Columns>
                    <Columns.Column>
                        <Message color="grey">
                            Achtung: Wenn du diese bestehenden
                            Vertragsbedingungen aktualisierst, müssen alle
                            Nutzer die diese bereits bestätigt haben die
                            aktualisierten Bedingungen erneut akzeptieren.
                        </Message>
                    </Columns.Column>
                </Columns>
            )}

            <br />
            <Columns>
                <Columns.Column>
                    <ValidationErrors error={validationErrors} />
                </Columns.Column>
            </Columns>

            <Columns>
                <Columns.Column>
                    <Button
                        disabled={saving}
                        loading={saving}
                        centered
                        color="redOutline"
                        type="submit"
                    >
                        Speichern
                    </Button>
                </Columns.Column>
            </Columns>
        </form>
    );
};

export default TermsOfServiceForm;
