import React from "react";

import { Router } from "@gatsbyjs/reach-router";
import { Helmet } from "react-helmet";

import { css } from "@emotion/react";
import styled from "@emotion/styled";

import Admin from "../../components/pages/CallForTender/Admin";
import Navigation from "../../components/pages/CallForTender/components/Navigation";
import Dashboard from "../../components/pages/CallForTender/Dashboard";
import Notification from "../../components/shared/Notification";
import Page from "../../components/shared/Page";
import ProtectedRoute from "../../components/shared/ProtectedRoute";

const Container = styled.div`
    ${({ theme }) => css`
        ${theme.paddings.containerOuter.left}
        ${theme.paddings.containerOuter.right}
        ${theme.paddings.containerOuter.bottom}

        color: ${theme.colors.blue.toString()};
        padding-top: 40px;
    `}
`;

const CftPage = () => {
    const withFont = css`
        font-family: var(--font-family-vito);
    `;

    return (
        <>
            <Helmet>
                <title>Ausschreibungsportal</title>
                <meta
                    name="description"
                    content="Hier findest du die ausgeschriebenen Projekte"
                />
            </Helmet>
            <Page>
                <Container css={withFont}>
                    <Navigation />
                    <Notification />
                    <Router>
                        <Dashboard path="/ausschreibungsportal/*" />
                        <Admin path="/ausschreibungsportal/admin/*" />
                    </Router>
                </Container>
            </Page>
        </>
    );
};

const ProtectedCftPage = (props) => (
    <ProtectedRoute {...props} component={CftPage} />
);

export default ProtectedCftPage;
