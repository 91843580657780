import React from "react";

import { useAuth } from "react-oidc-context";

import {
    decodeAccessToken,
    useIsAuthenticated,
} from "../../../../../providers/AuthProvider";
import Columns from "../../../../shared/Columns";
import LoadingIndicator from "../../../../shared/LoadingIndicator";
import Message from "../../../../shared/Message";
import Headline from "../../../../shared/NewHeadline";
import AccountCraftsForm from "../Profile/Forms/AccountCraftsForm";
import AccountForm from "../Profile/Forms/AccountForm";

const Register = ({ account }) => {
    const auth = useAuth();
    const isAuthenticated = useIsAuthenticated();

    let decodedToken = null;
    if (isAuthenticated) {
        decodedToken = decodeAccessToken(auth);
    }

    if (auth.isLoading) {
        return <LoadingIndicator />;
    }

    if (!account && isAuthenticated && decodedToken) {
        return <RegisterAccountComponent keycloakUser={decodedToken} />;
    }

    if (account && (!account.crafts || account.crafts.length < 1)) {
        return <RegisterCraftsComponent />;
    }
};

const RegisterAccountComponent = ({ keycloakUser }) => {
    return (
        <>
            <Columns>
                <Columns.Column>
                    <Headline lookLike={2}>
                        Registrierung für Bauausschreibungen
                    </Headline>
                </Columns.Column>
            </Columns>

            <Columns>
                <Columns.Column>
                    <Message color="grey">
                        Willkommen im Ausschreibungsportal. Bitte fülle die
                        folgenden Felder aus, um einen Account für das
                        Ausschreibungsportal anzulegen.
                    </Message>
                </Columns.Column>
            </Columns>

            <Columns>
                <Columns.Column>
                    <AccountForm keycloakUser={keycloakUser} />
                </Columns.Column>
            </Columns>
        </>
    );
};

const RegisterCraftsComponent = () => {
    return (
        <>
            <Columns>
                <Columns.Column>
                    <Headline color="blue">Meine Gewerke</Headline>
                    <Message color="grey">
                        Du hast aktuell keine Gewerke zugewiesen. Bitte wähle
                        Deine Gewerke aus und speichere diese ab.
                    </Message>
                    <AccountCraftsForm />
                </Columns.Column>
            </Columns>
        </>
    );
};

export default Register;
