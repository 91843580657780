/* eslint-disable @typescript-eslint/no-floating-promises */
import create from "zustand";

import {
    apiFetchProtected,
    errorAwareApiFetchProtected,
} from "../../../../../../apiBindings";
import createFieldSlices from "../../../../../../utils/store/fields";
import createFormErrorSlices from "../../../../../../utils/store/formErrors";

const baseUrl = "/call-for-tenders/admin/tenders";

const initialData = {
    id: null,
    projectId: "",
    stateId: "",
    name: "",
    description: "",
    estimatedStartAt: "",
    estimatedEndAt: "",
    applicationDeadlineAt: "",
    crafts: [],
    files: [],
    fileUploads: [],
    fileUploadNames: [],
    state: { name: "draft" },
    notify: false,
    notificationEmails: [],
};

const useStore = create((set, get) => ({
    ...createFormErrorSlices(set, get),
    ...createFieldSlices("saving", false, set),
    ...createFieldSlices("saved", false, set),

    data: { ...initialData },
    states: [],
    fileTypes: [],
    loadingStates: false,
    loadingFileTypes: false,
    deleting: false,
    validationErrors: undefined,

    setData: (data) => {
        set({ data });
    },
    setDataField: (field, value) => {
        const data = get().data;
        data[field] = value;
        set({ data });
    },
    setFileUploadName: (index, name) => {
        const data = get().data;
        data.fileUploadNames[index] = name;
        set({ data });
    },
    addFileUploads: (files) => {
        const data = get().data;
        set({
            data: {
                ...data,
                fileUploads: files,
                fileUploadNames: Array.from(files).map((file) => file.name),
            },
        });
    },
    removeFileUpload: (index) => {
        const data = get().data;
        const fileUploads = data.fileUploads.filter(
            (file, fileIndex) => fileIndex !== index
        );
        set({ data: { ...data, fileUploads } });
    },
    deleteFile: (file) => {
        const data = get().data;
        set({ deleting: true, error: "" });
        const url = `/call-for-tenders/admin/tenders/${data.id}/files/${file.id}`;
        apiFetchProtected(url, {
            method: "DELETE",
        })
            .then((response) => {
                data.files = data.files.filter(
                    (tenderFile) => tenderFile.id !== file.id
                );
                set({ data });
            })
            .catch(() => {
                set({ error: "Datei konnte nicht gelöscht werden" });
            })
            .finally(() => {
                set({ deleting: false });
            });
    },
    truncateData: (projectId) => {
        const data = {
            ...JSON.parse(JSON.stringify(initialData)),
            projectId: Number(projectId),
        };
        set({ data: data, saved: false });
    },
    save: (onSaved, publish = false) => {
        set({ saving: true, validationErrors: undefined });

        const data = get().data;
        let formData = new FormData();
        formData.append("project_id", data.projectId);
        formData.append("state_id", data.stateId);
        formData.append("name", data.name);
        formData.append("description", data.description);
        formData.append("estimated_start_at", data.estimatedStartAt);
        formData.append("estimated_end_at", data.estimatedEndAt);
        formData.append("application_deadline_at", data.applicationDeadlineAt);
        data.crafts.forEach((craft) => {
            formData.append("crafts[]", craft.id);
        });
        data.fileUploadNames.forEach((fileName) => {
            formData.append("file_upload_names[]", fileName);
        });
        Array.from(data.fileUploads).forEach((fileUpload) => {
            formData.append("file_uploads[]", fileUpload);
        });
        data.notificationEmails.forEach((email) => {
            formData.append("notification_emails[]", email);
        });
        formData.append("force_notification", data.notify);
        formData.append("publish", publish);

        const options = {
            method: "POST",
            body: formData,
        };

        const url = data.id ? `${baseUrl}/${data.id}` : baseUrl;

        errorAwareApiFetchProtected(url, options, true)
            .then((response) => {
                if (response?.data) {
                    set({ saved: true, data: { ...initialData } });
                    if (onSaved) {
                        onSaved(response.data);
                    }
                }
            })
            .catch((error) => {
                if (error.response.status === 422) {
                    set({ validationErrors: error.data });
                }
            })
            .finally(() => set({ saving: false }));
    },
    fetchStates: () => {
        set({ loadingStates: true });
        apiFetchProtected("/call-for-tenders/admin/states").then((response) => {
            set({ states: response.data, loadingStates: false });
        });
    },
    fetchFileTypes: () => {
        set({ loadingFileTypes: true });
        apiFetchProtected("/call-for-tenders/admin/file-types").then(
            (response) => {
                set({ fileTypes: response.data, loadingFileTypes: false });
            }
        );
    },
    deleteTender: (tenderId, onError, onDelete) => {
        const url = `/call-for-tenders/admin/tenders/${tenderId}`;
        apiFetchProtected(url, {
            method: "DELETE",
        })
            .then((response) => {
                if (response.data) {
                    if (onDelete) {
                        onDelete(response);
                    }
                }
                onError(response.message);
            })
            .finally(() => {
                set({ deleting: false });
            });
    },
}));

export default useStore;
