import React, { useEffect } from "react";

import { Router } from "@gatsbyjs/reach-router";

import { css, useTheme } from "@emotion/react";

import Container from "../../../shared/Container";
import LoadingIndicator from "../../../shared/LoadingIndicator";
import Message from "../../../shared/Message";
import Landing from "./Landing";
import Profile from "./Profile";
import Register from "./Register";
import useStore from "./store";
import Tender from "./Tender";

const Dashboard = () => {
    const theme = useTheme();
    const {
        account,
        accountLoaded,
        fetchAuthAccount,
        hasAuthError,
        isRegistered,
        accountCrafts,
    } = useStore();

    const newStyles = css`
        font-family: var(--font-family-vito);
        color: ${theme.colors.blue.toString()};
    `;

    useEffect(() => {
        if (!account) {
            fetchAuthAccount();
        }
    }, []);

    if (!accountLoaded) {
        return <LoadingIndicator />;
    }

    if (hasAuthError) {
        return (
            <Message>
                Es ist ein Fehler beim Abfragen deines Accounts aufgetreten,
                Bitte versuche es zu einem späteren Zeitpunkt erneut.
            </Message>
        );
    }

    if ((accountLoaded && !isRegistered) || accountCrafts.length < 1) {
        return (
            <Container slim css={newStyles}>
                <Register account={account} />
            </Container>
        );
    }

    return (
        <Router>
            <Landing path="/" />
            <Profile path="/profil" />
            <Tender path="/:id" />
        </Router>
    );
};

export default Dashboard;
