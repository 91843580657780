import React, { useEffect, useState } from "react";

import styled from "@emotion/styled";

const Row = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
`;

const Pill = styled.div`
    padding: 5px 20px;
    margin: 4px 2px;
    text-align: center;
    background-color: #ddd;
    border-radius: 25px;
`;

const Container = styled.div`
    display: flex;
`;

const CraftPills = ({ crafts, limit }) => {
    const [displayCrafts, setDisplayCrafts] = useState([]);
    const [remainingCrafts, setRemainingCrafts] = useState(0);

    useEffect(() => {
        if (!limit) {
            setDisplayCrafts(crafts);
            setRemainingCrafts(0);
            return;
        }

        const limited = crafts.filter((craft, index) => {
            if (index + 1 <= limit) {
                return craft;
            }
        });
        setRemainingCrafts(crafts.length - limited.length);
        setDisplayCrafts(limited);
    }, [crafts, limit]);

    if (crafts.length < 1) {
        return (
            <Container>
                <em>Du hast noch keine Gewerke hinterlegt.</em>
            </Container>
        );
    }
    return (
        <Container>
            <Row>
                {displayCrafts.map((craft) => (
                    <div key={craft.id}>
                        <Pill>{craft.name}</Pill>
                    </div>
                ))}{" "}
                {remainingCrafts > 0 && (
                    <div>
                        <em>...und {remainingCrafts} weitere </em>
                    </div>
                )}
            </Row>
        </Container>
    );
};

export default CraftPills;
