import React, { useEffect } from "react";

import { css } from "@emotion/react";
import styled from "@emotion/styled";

import Columns from "../../../../../../shared/Columns";
import NewInput from "../../../../../../shared/forms/NewInput";
import { SalutationSelect } from "../../../../../../shared/forms/NewSelect";
import { SearchInputWithGoogleMapsScript } from "../../../../../../shared/maps/SearchInput";
import Message from "../../../../../../shared/Message";
import Button from "../../../../../../shared/NewForms/Button";
import NewHeadline from "../../../../../../shared/NewHeadline";
import useSnackbar from "../../../../../../shared/Snackbar/store";
import AddressModal from "../../../../components/AddressModal";
import ValidationErrors from "../../../../components/ValidationErrors";
import useDashboardStore from "../../../store";
import useStore from "./store";

const AddressBlock = styled.blockquote`
    display: flex;
    flex-direction: column;
    font-weight: bold;
`;

const HeadlineWrapper = styled.div`
    display: flex;
    align-items: center;
    padding-top: 20px;
    margin-bottom: 10px;
`;

const AccountForm = ({ account, keycloakUser }) => {
    const { notify } = useSnackbar();
    const { setAccount, setIsRegistered } = useDashboardStore();
    const {
        data,
        setData,
        setDataField,
        saving,
        truncateData,
        save,
        errors,
        hasErrorAt,
        addError,
        clearError,
        setErrors,
        hasAddress,
        validationErrors,
        isModalVisible,
        setIsModalVisible,
    } = useStore();

    useEffect(() => {
        if (account) {
            setData({
                id: account.id,
                company: account.company,
                salutation: account.salutation || "",
                firstname: account.firstname,
                lastname: account.lastname,
                email: account.email,
                street: account.street,
                street_number: account.street_number,
                zipcode: account.zipcode,
                city: account.city,
                country: account.country,
                lat: account.lat,
                long: account.long,
            });
            //If the User has no Account yet (Register/store)
        } else if (keycloakUser) {
            setData({
                firstname: keycloakUser.given_name,
                lastname: keycloakUser.family_name,
                email: keycloakUser.email,
                company: "",
                salutation: "",
                street: "",
                street_number: "",
                zipcode: "",
                city: "",
                country: "",
                lat: "",
                long: "",
            });
        } else {
            truncateData();
        }
    }, [account, keycloakUser]);

    const onChange = (event) => {
        setDataField(event.target.name, event.target.value);
    };

    const onPlacesChange = (placesData) => {
        const result = placesData[0];

        setDataField("lat", result.geometry.location.lat());
        setDataField("long", placesData[0].geometry.location.lng());

        result.address_components.forEach((address) => {
            if (address.types.includes("route")) {
                setDataField("street", address.long_name);
            }
            if (address.types.includes("street_number")) {
                setDataField("street_number", address.long_name);
            }
            if (address.types.includes("locality")) {
                setDataField("city", address.long_name);
            }
            if (address.types.includes("postal_code")) {
                setDataField("zipcode", address.long_name);
            }
            if (address.types.includes("country")) {
                setDataField("country", address.long_name);
            }
        });
    };

    const onSubmit = (event) => {
        event.preventDefault();

        let localErrors = [];
        Object.keys(data).forEach((key) => {
            if (!["id"].includes(key)) {
                if (
                    (data[key] === "" || data[key] === null) &&
                    !hasErrorAt(key)
                ) {
                    localErrors.push(key);
                }
            }
        });

        setErrors(localErrors);

        if (!hasAddress()) {
            void notify("Fehler", "Gibt bitte Deine Adresse an.", "error");
        }

        if (errors.length === 0 && localErrors.length === 0) {
            const onSuccess = (updatedAccount) => {
                void notify(
                    "Erfolgreich",
                    "Das Profil wurde erfolgreich gespeichert."
                );
                setAccount(updatedAccount);
                setIsRegistered(true);
                window.scrollTo(0, 0);
            };

            save(onSuccess);
        }
    };

    const handleModal = (event) => {
        if ((event && event.target.classList.contains("backdrop")) || !event) {
            setIsModalVisible(!isModalVisible);
        }
    };

    return (
        <form onSubmit={onSubmit}>
            <Columns>
                <Columns.Column>
                    <NewHeadline color="blue">Kontaktdaten</NewHeadline>
                    <NewInput
                        name="company"
                        placeholder="Firma"
                        value={data.company}
                        withField
                        required
                        onChange={onChange}
                        onError={() => addError("company")}
                        onErrorSolved={() => clearError("company")}
                        forceError={hasErrorAt("company")}
                    />

                    <SalutationSelect
                        name="salutation"
                        options={[
                            { value: "", text: "" },
                            { value: "Herr", text: "Herr" },
                            { value: "Frau", text: "Frau" },
                            { value: "Divers", text: "Divers" },
                        ]}
                        onChange={onChange}
                        value={data.salutation}
                        onError={() => addError("salutation")}
                        onErrorSolved={() => clearError("salutation")}
                        forceError={hasErrorAt("salutation")}
                        validation="name"
                        required
                    />

                    <NewInput
                        name="firstname"
                        placeholder="Vorname"
                        value={data.firstname}
                        withField
                        required
                        onChange={onChange}
                        onError={() => addError("firstname")}
                        onErrorSolved={() => clearError("firstname")}
                        forceError={hasErrorAt("firstname")}
                        validation="name"
                    />

                    <NewInput
                        name="lastname"
                        placeholder="Nachname"
                        value={data.lastname}
                        withField
                        required
                        onChange={onChange}
                        onError={() => addError("lastname")}
                        onErrorSolved={() => clearError("lastname")}
                        forceError={hasErrorAt("lastname")}
                        validation="name"
                    />
                    <HeadlineWrapper>
                        <NewHeadline
                            color="blue"
                            css={css`
                                padding-bottom: 0;
                                margin-bottom: 0;
                            `}
                        >
                            Adresse
                        </NewHeadline>
                        {account && (
                            <AddressModal
                                handleModal={handleModal}
                                store={useStore}
                            />
                        )}
                    </HeadlineWrapper>
                    {!account && (
                        <>
                            <Message color="grey">
                                Um Deine Adresse zu finden, tippe bitte im Feld
                                „Adresse suchen“ Deine Straße ein. Sollte Deine
                                Adresse nicht unter den Vorschlägen zu finden
                                sein, gebe zusätzlich zur Straße auch Deinen Ort
                                ein. Sobald die richtige Anschrift auftaucht,
                                klicke den entsprechenden Eintrag in der Liste
                                an.
                            </Message>
                            <SearchInputWithGoogleMapsScript
                                withIcon={false}
                                placeholder="Adresse suchen"
                                onPlacesChange={onPlacesChange}
                                onKeyPress={(e) => {
                                    e.key === "Enter" && e.preventDefault();
                                }}
                            />
                            <NewInput
                                name="street_number"
                                placeholder="Hausnummer"
                                value={data.street_number}
                                withField
                                required
                                onChange={onChange}
                            />
                        </>
                    )}
                    <AddressBlock>
                        <span>
                            {data.street} {data.street_number}
                        </span>
                        <span>
                            {data.zipcode} {data.city}
                        </span>
                        <span>{data.country}</span>
                    </AddressBlock>
                    <br />
                </Columns.Column>
            </Columns>

            {validationErrors && (
                <Columns>
                    <Columns.Column>
                        <ValidationErrors error={validationErrors} />
                    </Columns.Column>
                </Columns>
            )}

            <Columns>
                <Columns.Column>
                    <Button
                        disabled={saving}
                        loading={saving}
                        type="submit"
                        centered
                        color="redOutline"
                    >
                        Profildaten speichern
                    </Button>
                </Columns.Column>
            </Columns>
        </form>
    );
};

export default AccountForm;
