import React, { useEffect, useState } from "react";

import { useParams } from "@gatsbyjs/reach-router";

import LoadingIndicator from "../../../../../shared/LoadingIndicator";
import useProjectsStore from "../../Projects/store";
import Form from "../Form";

const Edit = () => {
    const { id } = useParams();
    const { projects, fetchProjects, getTenderById, loading } =
        useProjectsStore();

    const [tender, setTender] = useState(null);

    useEffect(() => {
        if (id) {
            if (projects.length === 0) {
                fetchProjects();
            }
        }
    }, []);

    useEffect(() => {
        if (id && !loading) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            setTender(getTenderById(id));
        }
    }, [loading]);

    return loading ? <LoadingIndicator /> : <Form tender={tender} />;
};

export default Edit;
