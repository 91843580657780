/* eslint-disable @typescript-eslint/no-floating-promises */
import create from "zustand";

import {
    apiFetchProtected,
    errorAwareApiFetchProtected,
} from "../../../../../../apiBindings";
import createFormErrorSlices from "../../../../../../utils/store/formErrors";

const baseUrl = "/call-for-tenders/admin/projects";

const initialData = {
    id: null,
    name: "",
    description: "",
    street: "",
    street_number: "",
    zipcode: "",
    city: "",
    lat: "",
    long: "",
    estimatedStartAt: "",
    estimatedEndAt: "",
    contactSalutation: "Herr",
    contactFirstname: "",
    contactLastname: "",
    contactPhone: "",
    contactEmail: "",
    ownerName: "",
    ownerStreet: "",
    ownerStreetNumber: "",
    ownerZipcode: "",
    ownerCity: "",
    files: [],
    fileUploads: [],
    fileUploadNames: [],
};

const useStore = create((set, get) => ({
    ...createFormErrorSlices(set, get),

    data: { ...initialData },
    isModalVisible: false,
    saving: false,
    saved: true,
    deleting: false,
    validationErrors: undefined,

    setData: (data) => {
        set({ data });
    },
    setDataField: (field, value) => {
        const data = get().data;
        data[field] = value;
        set({ data });
    },
    setIsModalVisible: (isModalVisible) => {
        set({ isModalVisible });
    },
    setSaving: (saving) => {
        set({ saving });
    },
    setSaved: (saved) => {
        set({ saved });
    },
    setDeleting: (deleting) => {
        set({ deleting });
    },
    truncateData: () => {
        set({ data: { ...initialData }, saved: false });
    },
    setFileUploadName: (index, name) => {
        const data = get().data;
        data.fileUploadNames[index] = name;
        set({ data });
    },
    addFileUploads: (files) => {
        const data = get().data;
        set({
            data: {
                ...data,
                fileUploads: files,
                fileUploadNames: Array.from(files).map((file) => file.name),
            },
        });
    },
    removeFileUpload: (index) => {
        const data = get().data;
        const fileUploads = data.fileUploads.filter(
            (file, fileIndex) => fileIndex !== index
        );
        set({ data: { ...data, fileUploads } });
    },
    deleteFile: (file) => {
        const data = get().data;
        set({ deleting: true, error: "" });
        const url = `${baseUrl}/${data.id}/files/${file.id}`;
        apiFetchProtected(url, {
            method: "DELETE",
        })
            .then((response) => {
                data.files = data.files.filter(
                    (projectFile) => projectFile.id !== file.id
                );
                set({ data });
            })
            .catch(() => {
                set({ error: "Datei konnte nicht gelöscht werden" });
            })
            .finally(() => {
                set({ deleting: false });
            });
    },
    save: (onSaved) => {
        set({ saving: true, validationErrors: undefined });

        const data = get().data;

        let formData = new FormData();
        formData.append("name", data.name);
        formData.append("description", data.description);
        formData.append("name", data.name);
        formData.append("street", data.street);
        formData.append("street_number", data.street_number);
        formData.append("zipcode", data.zipcode);
        formData.append("city", data.city);
        formData.append("lat", data.lat);
        formData.append("long", data.long);
        formData.append("estimated_start_at", data.estimatedStartAt);
        formData.append("estimated_end_at", data.estimatedEndAt);
        formData.append("contact_salutation", data.contactSalutation);
        formData.append("contact_firstname", data.contactFirstname);
        formData.append("contact_lastname", data.contactLastname);
        formData.append("contact_phone", data.contactPhone);
        formData.append("owner_name", data.ownerName);
        formData.append("owner_street", data.ownerStreet);
        formData.append("owner_street_number", data.ownerStreetNumber);
        formData.append("owner_zipcode", data.ownerZipcode);
        formData.append("owner_city", data.ownerCity);
        formData.append("contact_email", data.contactEmail);
        data.fileUploadNames.forEach((fileName) => {
            formData.append("file_upload_names[]", fileName);
        });
        Array.from(data.fileUploads).forEach((fileUpload) => {
            formData.append("file_uploads[]", fileUpload);
        });

        const options = {
            method: "POST",
            body: formData,
        };

        const url = data.id ? `${baseUrl}/${data.id}` : baseUrl;

        errorAwareApiFetchProtected(url, options, true)
            .then((response) => {
                if (response?.data) {
                    set({ saved: true });
                    if (onSaved) {
                        onSaved(response.data);
                    }
                }
            })
            .catch((error) => {
                if (error.response.status === 422) {
                    set({ validationErrors: error.data });
                }
            })
            .finally(() => set({ saving: false, data: { ...initialData } }));
    },
}));

export default useStore;
