import React, { useEffect } from "react";

import { css } from "@emotion/react";
import styled from "@emotion/styled";

import Columns from "../../../../../components/shared/Columns";
import LoadingIndicator from "../../../../../components/shared/LoadingIndicator";
import Button from "../../../../../components/shared/NewForms/Button";
import Headline from "../../../../../components/shared/NewHeadline";
import Input from "../../../../shared/forms/NewInput";
import useSnackbar from "../../../../shared/Snackbar/store";
import Box from "../../components/Box";
import ValidationErrors from "../../components/ValidationErrors";
import useStore from "./store";

const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    ${({ theme }) => theme.breakpoints.from.small.css`
       flex-direction: row;
       gap: 24px;
    `}

    align-items: flex-start;
`;

const Crafts = () => {
    const { confirm, notify } = useSnackbar();
    const {
        loading,
        crafts,
        fetchCrafts,
        craftToAdd,
        setCraftToAdd,
        saving,
        saveCraft,
        deleting,
        deleteCraft,
        validationErrors,
    } = useStore();

    useEffect(() => {
        if (crafts === undefined) {
            fetchCrafts();
        }
    }, []);

    const onChange = (event) => {
        setCraftToAdd(event.target.value);
    };

    const onSubmit = (event) => {
        event.preventDefault();
        const onSuccess = () => {
            void notify(
                "Erfolgreich",
                `Das Gewerk ${craftToAdd} wurde erfolgreich hinzugefügt.`
            );
            fetchCrafts();
        };
        const onError = (msg) => {
            return notify("Fehlgeschlagen", msg, "error");
        };

        saveCraft(onSuccess, onError);
    };

    const onDelete = (craft) => {
        confirm(
            "Gewerk löschen",
            `Möchtest du das Gewerk "${craft.name}" wirklich löschen?`
        ).then((confirmed) => {
            if (confirmed) {
                deleteCraft(
                    craft.id,
                    () => {
                        notify(
                            "Erfolgreich gelöscht",
                            `Das Gewerk wurde erfolgreich gelöscht.`
                        );
                    },
                    (message) => {
                        notify("Fehler", message, "error");
                    }
                );
            }
        });
    };

    if (loading) {
        return <LoadingIndicator />;
    }

    return (
        <>
            <Columns>
                <Columns.Column>
                    <Headline color="blue">Neues Gewerk hinzufügen</Headline>
                    <form onSubmit={onSubmit}>
                        <FormContainer>
                            <Input
                                value={craftToAdd}
                                onChange={onChange}
                                name="name"
                                placeholder="Gewerk / Name"
                                withField
                                required
                                css={css`
                                    min-width: 400px;
                                `}
                            />
                            <Button
                                type="submit"
                                disabled={saving}
                                loading={saving}
                                css={css`
                                    margin-top: 5px;
                                `}
                            >
                                Hinzufügen
                            </Button>
                        </FormContainer>
                    </form>
                </Columns.Column>
            </Columns>

            <Columns>
                <Columns.Column>
                    <ValidationErrors error={validationErrors} />
                </Columns.Column>
            </Columns>

            <Columns>
                <Columns.Column>
                    <Headline color="blue">Gewerke</Headline>

                    {crafts && crafts.length > 0 && (
                        <div>
                            {crafts.map((craft) => (
                                <Box key={craft.id}>
                                    <Columns>
                                        <Columns.Column>
                                            <strong>{craft.name}</strong>
                                        </Columns.Column>
                                        <Columns.Column>
                                            Ausschreibungen:{" "}
                                            {craft.tenders_count ?? 0}
                                        </Columns.Column>
                                        <Columns.Column>
                                            Benutzer:{" "}
                                            {craft.craft_accounts_count ?? 0}
                                        </Columns.Column>
                                        <Columns.Column desktop="one-fifth">
                                            <Button
                                                disabled={deleting}
                                                small
                                                onClick={() => onDelete(craft)}
                                            >
                                                Löschen
                                            </Button>
                                        </Columns.Column>
                                    </Columns>
                                </Box>
                            ))}
                        </div>
                    )}
                </Columns.Column>
            </Columns>
        </>
    );
};

export default Crafts;
