import React, { useEffect, useState } from "react";

import styled from "@emotion/styled";

import Message from "../../../../shared/Message";
import NewHeadline from "../../../../shared/NewHeadline";

/**
 * Shows validation errors from the backend.
 * @param error The unformatted Laravel error from a 422 response.
 * @returns {JSX.Element}
 */
const ValidationErrors = ({ error }) => {
    const [errors, setErrors] = useState([]);

    useEffect(() => {
        if (error && error.errors) {
            setErrors(Object.entries(error.errors));
        }
    }, [error]);

    return (
        <>
            {errors.length > 0 && (
                <Message>
                    <NewHeadline lookLike={3} color="blue">
                        Validierung fehlgeschlagen
                    </NewHeadline>
                    {errors?.map((error) => (
                        /* index=0 is the field name; index=1 is the array with error-messages for that field */
                        <ErrorMessages key={error[0]} messages={error[1]} />
                    ))}
                </Message>
            )}
        </>
    );
};

const ErrorParagraph = styled.p`
    ::first-letter {
        text-transform: capitalize;
    }
`;

const ErrorMessages = ({ messages }) => {
    if (!messages || messages.length < 1) {
        return null;
    }
    return (
        <>
            {messages.map((message, index) => (
                <ErrorParagraph key={index}>{message}</ErrorParagraph>
            ))}
        </>
    );
};

export default ValidationErrors;
