/**
 * Adds a setter and a field with the given name and the given initial value to a zustand store.
 *
 * @param {*} fieldName
 * @param {*} initialValue
 * @param {*} set
 * @returns
 */
const createFieldSlices = (fieldName, initialValue, set) => {
    const setterName = `set${
        fieldName[0].toUpperCase() + fieldName.substring(1)
    }`;

    return {
        [fieldName]: initialValue,
        [setterName]: (value) => set({ [fieldName]: value }),
    };
};

export default createFieldSlices;
