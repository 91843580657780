import React, { useEffect } from "react";

import { css } from "@emotion/react";

import LoadingIndicator from "../../../../shared/LoadingIndicator";
import Headline from "../../../../shared/NewHeadline";
import Box from "../../components/Box";
import FileList from "../../components/FileList";
import TenderItem from "../../components/TenderItem";
import useDashboardStore from "../store";
import TermsOfService from "../TermsOfService";

const Tender = (route) => {
    const {
        loading,
        tender,
        fetchTenderById,
        unacceptedTermsOfService,
        account,
    } = useDashboardStore();

    const fileHeadlineStyles = css`
        margin-top: 40px;
        margin-bottom: 40px;
    `;

    useEffect(() => {
        fetchTenderById(route.id);
    }, []);

    if (loading) {
        return <LoadingIndicator />;
    }

    if (unacceptedTermsOfService.length > 0) {
        return (
            <TermsOfService
                termsOfService={account.unaccepted_terms_of_service}
            />
        );
    }

    if (tender) {
        return (
            <>
                <Headline>Ausschreibungsdetails</Headline>
                <Box>
                    <TenderItem
                        tender={tender}
                        name
                        truncateDescription
                        description
                        showContact
                        showOwner
                        showProjectDescription
                    />
                    {(tender.project.files.length > 0 ||
                        tender.files.length > 0 ||
                        tender?.terms_of_service_files > 0) && (
                        <>
                            <Headline css={fileHeadlineStyles}>
                                Unterlagen
                            </Headline>
                            <FileList
                                files={tender.files}
                                withEmptyMessage={false}
                            />
                            <FileList
                                files={tender.project.files}
                                withEmptyMessage={false}
                            />
                            <FileList
                                files={tender?.terms_of_service_files ?? []}
                                withEmptyMessage={false}
                            />
                        </>
                    )}
                </Box>
            </>
        );
    }
    return null;
};

export default Tender;
