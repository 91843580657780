import React, { useEffect, useState } from "react";

import { useParams } from "@gatsbyjs/reach-router";

import LoadingIndicator from "../../../../../shared/LoadingIndicator";
import Headline from "../../../../../shared/NewHeadline";
import Form from "../Form";
import useStore from "../store";

const Edit = () => {
    const { id } = useParams();
    const { loading, lists, fetchLists, getListById } = useStore();
    const [list, setList] = useState(null);

    useEffect(() => {
        if (id) {
            if (lists.length === 0) {
                fetchLists();
            }
        }
    }, []);

    useEffect(() => {
        if (id && !loading) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            setList(getListById(id));
        }
    }, [loading]);

    return (
        <>
            <Headline>E-Mail Verteiler bearbeiten</Headline>
            {loading ? <LoadingIndicator /> : <Form list={list} />}
        </>
    );
};

export default Edit;
