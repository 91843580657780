import React, { useEffect, useState } from "react";

import { css } from "@emotion/react";
import styled from "@emotion/styled";

import Columns from "../../../../shared/Columns";
import NewSelect from "../../../../shared/forms/NewSelect";
import LoadingIndicator from "../../../../shared/LoadingIndicator";
import Message from "../../../../shared/Message";
import Button from "../../../../shared/NewForms/Button";
import NewHeadline from "../../../../shared/NewHeadline";
import useStore from "./store";

const CraftList = styled.div`
    display: flex;
    flex-direction: column;
    ${({ theme }) => theme.breakpoints.from.small.css`
       flex-direction: row;
    `}
    justify-content: space-between;
    padding: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius: 4px;
    ${({ theme }) => css`
        border: 1px solid ${theme.colors.darkGrey.toString()};
    `}
`;

const SelectContainer = styled.div`
    display: flex;
    align-items: center;
    justify-items: center;
    width: 100%;
`;

const CraftsHeadline = styled.p`
    color: ${({ theme }) => theme.colors.blue.toString()};
    font-weight: 500;
`;

const mapOptions = (crafts) => {
    if (!crafts || crafts.length < 1) {
        return [];
    }
    const defaultOption = { text: "Nichts ausgewählt", value: "" };
    const mapped = crafts.map((craft) => {
        return {
            text: craft.name,
            value: craft.id,
        };
    });
    return [defaultOption, ...mapped];
};

const CraftPicker = ({
    selectedCrafts = [],
    onChange,
    addNewTitle,
    craftsTitle,
}) => {
    const { loading, crafts, fetchCrafts } = useStore();
    const [selectedCraft, setSelectedCraft] = useState("");

    useEffect(() => {
        if (crafts === undefined) {
            fetchCrafts();
        }
    }, [crafts, fetchCrafts]);

    const mapSelectOptions = () => {
        const defaultOption = { text: "Bitte auswählen", value: "" };
        let mapped = [];
        crafts.forEach((craft) => {
            //Don't make items available that are already in the list
            const isAlreadySelected = selectedCrafts.find(
                (selected) => selected.id === craft.id
            );
            if (!isAlreadySelected) {
                mapped.push({ text: craft.name, value: craft.id });
            }
        });
        return [defaultOption, ...mapped];
    };

    const onCraftSelected = (event) => {
        const craftId = event.target.value;
        if (!craftId) {
            return;
        }
        const craftToAdd = crafts.find(
            (craft) => craft.id === parseInt(craftId)
        );
        setSelectedCraft(craftToAdd);

        const updatedCrafts = selectedCrafts;
        updatedCrafts.push(craftToAdd);
        onChange(updatedCrafts);
        setSelectedCraft("");
    };

    const removeCraft = (event, craft) => {
        event.preventDefault();
        const updatedCrafts = selectedCrafts.filter(
            (userCraft) => userCraft.id !== craft.id
        );
        return onChange(updatedCrafts);
    };

    if (!crafts || crafts.length === 0) {
        return (
            <Message color="grey">
                Es konnten keine Gewerke gefunden werden.
            </Message>
        );
    }

    return (
        <>
            {!loading && (
                <>
                    <Columns>
                        <Columns.Column>
                            {addNewTitle && (
                                <NewHeadline
                                    color="blue"
                                    css={css`
                                        padding-top: 20px;
                                    `}
                                >
                                    {addNewTitle}
                                </NewHeadline>
                            )}

                            <div className="field has-addons">
                                <SelectContainer>
                                    <div
                                        className="control"
                                        css={css`
                                            width: 100%;
                                        `}
                                    >
                                        <NewSelect
                                            value={selectedCraft?.id ?? ""}
                                            options={mapSelectOptions()}
                                            onChange={onCraftSelected}
                                        />
                                    </div>
                                </SelectContainer>
                            </div>
                        </Columns.Column>
                    </Columns>
                </>
            )}

            <Columns>
                <Columns.Column>
                    {craftsTitle && (
                        <CraftsHeadline color="blue">
                            {craftsTitle}
                        </CraftsHeadline>
                    )}

                    {selectedCrafts.length === 0 && (
                        <CraftList>Keine ausgewählten Gewerke.</CraftList>
                    )}

                    {selectedCrafts.map((selectedCraft) => {
                        return (
                            <CraftList key={selectedCraft.id}>
                                <span>{selectedCraft.name}</span>
                                <span>
                                    <Button
                                        extraSmall
                                        onClick={(event) =>
                                            removeCraft(event, selectedCraft)
                                        }
                                    >
                                        Entfernen
                                    </Button>
                                </span>
                            </CraftList>
                        );
                    })}
                </Columns.Column>
            </Columns>
        </>
    );
};

export const CraftSelect = ({
    value,
    placeholder = "Gewerk auswählen",
    onChange,
    name,
}) => {
    const { loading, crafts, fetchCrafts } = useStore();
    useEffect(() => {
        if (crafts === undefined) {
            fetchCrafts();
        }
    }, [crafts, fetchCrafts]);

    if (loading) {
        return <LoadingIndicator />;
    }

    return (
        <NewSelect
            name={name}
            value={value}
            options={mapOptions(crafts)}
            onChange={onChange}
            placeholder={placeholder}
        />
    );
};

export default CraftPicker;
