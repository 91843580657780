import create from "zustand";

import { errorAwareApiFetchProtected } from "../../../../../../../apiBindings";
import createFieldSlices from "../../../../../../../utils/store/fields";
import createFormErrorSlices from "../../../../../../../utils/store/formErrors";

const initialData = {
    id: null,
    company: "",
    salutation: "",
    firstname: "",
    lastname: "",
    street: "",
    street_number: "",
    zipcode: "",
    city: "",
    country: "",
    lat: "",
    long: "",
};

const useStore = create((set, get) => ({
    ...createFieldSlices("isModalVisible", false, set),
    ...createFieldSlices("saved", false, set),
    ...createFieldSlices("saving", false, set),
    ...createFormErrorSlices(set, get),

    data: initialData,
    validationErrors: undefined,
    setData: (data) => {
        set({ data });
    },
    setDataField: (field, value) => {
        const data = get().data;
        data[field] = value;
        set({ data });
    },
    truncateData: () => {
        set({ data: initialData, saved: false });
    },
    save: (onSaved) => {
        set({ saving: true, validationErrors: undefined });
        const data = get().data;
        let method = "PUT";
        let url = `/call-for-tenders/account/${data.id}`;

        const payload = {
            company: data.company,
            salutation: data.salutation,
            firstname: data.firstname,
            lastname: data.lastname,
            street: data.street,
            street_number: data.street_number,
            zipcode: data.zipcode,
            city: data.city,
            country: data.country,
            lat: data.lat,
            long: data.long,
        };

        if (!data.id) {
            method = "POST";
            url = "/call-for-tenders/account";
        }

        errorAwareApiFetchProtected(url, {
            method,
            body: JSON.stringify(payload),
        })
            .then((response) => {
                set({ saved: true });
                if (onSaved) {
                    onSaved(response.data);
                }
            })
            .catch((error) => {
                if (error.response.status === 422) {
                    set({ validationErrors: error.data });
                }
            })
            .finally(() => {
                set({ saving: false });
            });
    },
    hasAddress: () => {
        const { data } = get();
        return (
            data.street !== "" &&
            data.city !== "" &&
            data.street_number !== "" &&
            data.zipcode !== ""
        );
    },
}));

export default useStore;
