import React from "react";

import { useLocation } from "@gatsbyjs/reach-router";

import styled from "@emotion/styled";

import LinkComponent from "../../../../shared/LinkComponent";

const NavContainer = styled.div`
    display: flex;
`;

const stripUrlParts = (url, n) => {
    const backPathParts = url.split("/");
    const backPath = backPathParts
        .filter((value, key) => key <= backPathParts.length - 2 - n)
        .join("/");

    return backPath;
};

const Navigation = () => {
    const location = useLocation();

    const showBackLink = !location.pathname.endsWith("/ausschreibungsportal/");
    const tenderCreateRegex = /[0-9]+\/ausschreibungen\/erstellen\/$/;
    const tenderUpdateRegex = /[0-9]+\/ausschreibungen\/[0-9]+\/bearbeiten\/$/;
    const distributionListUpdateRegex = /\/verteiler\/[0-9]+\/bearbeiten\/$/;
    const partsToSkip =
        tenderCreateRegex.test(location.pathname) ||
        distributionListUpdateRegex.test(location.pathname)
            ? 2
            : tenderUpdateRegex.test(location.pathname)
            ? 3
            : 1;
    const backPath = stripUrlParts(location.pathname, partsToSkip);

    if (!showBackLink) {
        return null;
    }

    return (
        <NavContainer>
            <LinkComponent
                link={{ href: backPath, text: "zurück" }}
                icon="arrow-left"
                leftIcon
            />
        </NavContainer>
    );
};

export default Navigation;
