export const truncateString = (
    text,
    countWords = 200,
    placeholder = "[...]"
) => {
    if (!text) {
        return "";
    }
    const words = text.split(" ");

    if (words.length > countWords) {
        return words.splice(0, countWords).join(" ").concat(placeholder);
    }
    return text;
};
