import create from "zustand";

import {
    apiFetchProtected,
    errorAwareApiFetchProtected,
} from "../../../../../../../apiBindings";
import createFieldSlices from "../../../../../../../utils/store/fields";

const baseUrl = "/call-for-tenders/account/crafts";
const useStore = create((set, get) => ({
    ...createFieldSlices("userCrafts", undefined, set),

    saving: false,
    loading: false,
    validationErrors: undefined,
    fetchUserCrafts: () => {
        set({ loading: true });
        apiFetchProtected(baseUrl)
            .then((response) => {
                set({ userCrafts: response.data });
            })
            .finally(() => set({ loading: false }));
    },
    update: (onSuccess) => {
        set({ saving: true, validationErrors: undefined });
        const payload = {
            crafts: get().userCrafts.map((craft) => craft.id),
        };

        errorAwareApiFetchProtected(`${baseUrl}`, {
            method: "PUT",
            body: JSON.stringify(payload),
        })
            .then((response) => {
                if (response?.data) {
                    set({ userCrafts: response.data });
                    if (onSuccess) {
                        onSuccess(response.data);
                    }
                }
            })
            .catch((error) => {
                if (error.response.status === 422) {
                    set({ validationErrors: error.data });
                }
            })
            .finally(() => set({ saving: false }));
    },
}));

export default useStore;
