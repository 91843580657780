import React, { useEffect } from "react";

import LoadingIndicator from "../../../../../shared/LoadingIndicator";
import Headline from "../../../../../shared/NewHeadline";
import useSnackbar from "../../../../../shared/Snackbar/store";
import Box from "../../../components/Box";
import TermsOfServiceForm from "../Form";
import useStore from "../store";

const CreateTermsOfService = () => {
    const { setForm, create, loading, resetForm } = useStore();
    const { notify } = useSnackbar();

    useEffect(() => {
        resetForm();
    }, []);

    const onSubmit = (event) => {
        event.preventDefault();
        const onSuccess = () => {
            resetForm();
            return notify(
                "Erfolgreich",
                "Vertragsbedingungen wurden gespeichert."
            );
        };
        const onError = (msg) => {
            return notify("Fehlgeschlagen", msg, "error");
        };
        create(onSuccess, onError);
    };

    const onChange = (event) => {
        const target = event.target;
        setForm(target.name, target.value);
    };

    const onFileChange = (event) => {
        const file = event.target.files[0];
        setForm("file", file);
    };

    return (
        <Box>
            <Headline>Neue Vertragsbedingungen erstellen</Headline>
            {loading && <LoadingIndicator />}
            {!loading && (
                <TermsOfServiceForm
                    onChange={onChange}
                    onFileChange={onFileChange}
                    onSubmit={onSubmit}
                />
            )}
        </Box>
    );
};

export default CreateTermsOfService;
