import React, { useEffect } from "react";

import NewHeadline from "../../../../../shared/NewHeadline";
import Form from "../Form";
import useStore from "../store";

const Create = () => {
    const { projects, fetchProjects } = useStore();

    useEffect(() => {
        if (projects.length === 0) {
            fetchProjects();
        }
    }, []);

    return (
        <>
            <NewHeadline>Projekt anlegen</NewHeadline>
            <Form />
        </>
    );
};

export default Create;
