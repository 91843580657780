import React, { useCallback, useEffect, useState } from "react";

import styled from "@emotion/styled";

import Columns from "../../../../shared/Columns";
import Checkbox from "../../../../shared/forms/NewCheckbox";
import Button from "../../../../shared/NewForms/Button";
import useStore from "../../Admin/DistributionLists/store";
import Box from "../Box";

const EmailRow = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    ${({ theme }) => theme.breakpoints.from.small.css`
          flex-direction: row;
          gap: unset;
          align-items: center;
     `}
    justify-content: space-between;
`;

const ListName = styled.div`
    font-weight: 500;
`;

const SubActions = styled.div`
    margin-top: 20px;
`;

const Emails = styled.div`
    margin-top: 20px;
`;

const ListActions = styled.div`
    display: flex;
    gap: 20px;
`;

const DistributionListSelector = ({
    selectedEmails,
    onSelectEmails,
    onDeselectEmails,
}) => {
    const { lists, fetchLists } = useStore();
    const [expanded, setExpanded] = useState([]);

    useEffect(() => {
        if (lists.length === 0) {
            fetchLists();
        }
    }, []);

    const toggleExpanded = (listId) => {
        if (expanded.includes(listId)) {
            setExpanded(
                expanded.filter((expandedListId) => expandedListId !== listId)
            );
        } else {
            setExpanded([...expanded, listId]);
        }
    };

    const selectAll = (list) => {
        onSelectEmails(list.emails.map((email) => email.email));
    };

    const deselectAll = (list) => {
        onDeselectEmails(list.emails.map((email) => email.email));
    };

    const getSelectedEmailsFromList = useCallback(
        (list) =>
            list.emails.filter((email) => selectedEmails.includes(email.email)),
        [selectedEmails]
    );

    const isFullySelected = (list) =>
        getSelectedEmailsFromList(list).length === list.emails.length;

    return (
        <div>
            {lists.map((list) => (
                <Box key={list.id}>
                    <EmailRow>
                        <ListName>{list.name}</ListName>
                        <div>
                            {getSelectedEmailsFromList(list).length} ausgewählt
                        </div>
                        <ListActions>
                            <Button
                                type="button"
                                color="blueOutline"
                                onClick={() => toggleExpanded(list.id)}
                            >
                                Alle{" "}
                                {expanded.includes(list.id)
                                    ? "ausblenden"
                                    : "anzeigen"}
                            </Button>
                        </ListActions>
                    </EmailRow>
                    {expanded.includes(list.id) && (
                        <>
                            <SubActions>
                                <Checkbox
                                    label="Alle auswählen"
                                    checked={isFullySelected(list)}
                                    onClick={() =>
                                        isFullySelected(list)
                                            ? deselectAll(list)
                                            : selectAll(list)
                                    }
                                />
                            </SubActions>
                            <Emails>
                                <Columns>
                                    {list.emails.map((email) => (
                                        <Columns.Column
                                            key={email.id}
                                            desktop="one-third"
                                            tablet="half"
                                            mobile="full"
                                        >
                                            <EmailRow>
                                                <Checkbox
                                                    label={email.email}
                                                    checked={selectedEmails.includes(
                                                        email.email
                                                    )}
                                                    onClick={() =>
                                                        selectedEmails.includes(
                                                            email.email
                                                        )
                                                            ? onDeselectEmails([
                                                                  email.email,
                                                              ])
                                                            : onSelectEmails([
                                                                  email.email,
                                                              ])
                                                    }
                                                />
                                            </EmailRow>
                                        </Columns.Column>
                                    ))}
                                </Columns>
                            </Emails>
                        </>
                    )}
                </Box>
            ))}
        </div>
    );
};

export default DistributionListSelector;
