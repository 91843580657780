import React, { useEffect } from "react";

import { useAuth } from "react-oidc-context";

import {
    hasPreviousAuthPath,
    setPreviousAuthPath,
    useAutomaticallySignIn,
    useKeycloakOpt,
} from "../../../providers/AuthProvider";
import LoadingIndicator from "../LoadingIndicator";
import Margin from "../Margin";

const ProtectedRoute = ({ component: Component, ...props }) => {
    // useAutomaticallySignIn redirects automatically to keycloak if not authenticated
    useAutomaticallySignIn();
    const { authenticated } = useKeycloakOpt();
    const auth = useAuth();

    useEffect(() => {
        if (!auth) {
            return; /* Possible blocks initial value. */
        }

        if (auth.error) {
            if (!hasPreviousAuthPath()) {
                setPreviousAuthPath();
            }
            void auth.signinRedirect();
        }

        auth.events.addAccessTokenExpiring(() => {
            void auth.signinSilent();
        });
    }, [auth?.error, auth.events]);

    if (authenticated) {
        return <Component {...props} />;
    }

    return (
        <Margin top="40">
            <LoadingIndicator />
        </Margin>
    );
};
export default ProtectedRoute;
