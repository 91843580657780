/* eslint-disable @typescript-eslint/no-unsafe-argument */
import React, { useEffect } from "react";

import Columns from "../../../../../../shared/Columns";
import LoadingIndicator from "../../../../../../shared/LoadingIndicator";
import Message from "../../../../../../shared/Message";
import Button from "../../../../../../shared/NewForms/Button";
import NewHeadline from "../../../../../../shared/NewHeadline";
import useSnackbar from "../../../../../../shared/Snackbar/store";
import FileList from "../../../../components/FileList";
import ValidationErrors from "../../../../components/ValidationErrors";
import useStore from "./store";

const AccountCertificateForm = () => {
    const { confirm } = useSnackbar();
    const {
        certificate,
        fetchCertificate,
        uploadCertificate,
        deleteCertificate,
        loading,
        uploading,
        deleting,
        file,
        filename,
        setFile,
        setFilename,
        validationErrors,
    } = useStore();

    useEffect(() => {
        if (certificate === undefined) {
            fetchCertificate();
        }
    }, []);

    const onSubmit = (event) => {
        event.preventDefault();
        uploadCertificate(file, filename);
    };

    const onFileChange = (event) => {
        const file = event.target.files[0];
        setFilename("Zertifikat");
        setFile(file);
    };

    const onDelete = (event, certificate) => {
        event.preventDefault();
        confirm(
            "Zertifikat löschen",
            "Möchtest du das Zertifikat wirklich löschen?"
        ).then((confirmed) => {
            if (confirmed) {
                deleteCertificate(certificate);
            }
        });
    };

    if (loading) {
        return <LoadingIndicator />;
    }

    if (!certificate) {
        return (
            <form onSubmit={onSubmit}>
                <Columns>
                    <Columns.Column>
                        <NewHeadline color="blue">
                            Zertifikat hochladen
                        </NewHeadline>
                        <Message color="grey">
                            Sollte dein Betrieb eine
                            Wasserhaushaltsgesetz-Zertifizierung haben, kannst
                            du das Zertifikat hier als .pdf-Datei hochladen.
                            Dies gilt nur, wenn du die Gewerke{" "}
                            <strong>Betonbau</strong> oder{" "}
                            <strong>Tiefbau</strong> ausgewählt hast.
                        </Message>
                    </Columns.Column>
                </Columns>

                <Columns>
                    <Columns.Column>
                        <NewHeadline color="blue">
                            Zertifikat auswählen
                        </NewHeadline>
                        <input
                            type="file"
                            accept="application/pdf"
                            onChange={onFileChange}
                        />
                    </Columns.Column>
                </Columns>
                {validationErrors && (
                    <Columns>
                        <Columns.Column>
                            <ValidationErrors error={validationErrors} />
                        </Columns.Column>
                    </Columns>
                )}
                <Button
                    centered
                    color="redOutline"
                    type="submit"
                    disabled={uploading}
                    loading={uploading}
                >
                    Zertifikat speichern
                </Button>
            </form>
        );
    } else {
        return (
            <form onSubmit={onDelete}>
                <NewHeadline color="blue">Mein Zertifikat</NewHeadline>
                <FileList files={[certificate]} />
                <Button
                    centered
                    color="redOutline"
                    type="submit"
                    disabled={deleting}
                    loading={deleting}
                >
                    Zertifikat löschen
                </Button>
            </form>
        );
    }
};
export default AccountCertificateForm;
