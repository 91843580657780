import React, { useEffect } from "react";

import { useParams } from "@gatsbyjs/reach-router";

import { navigate } from "../../../../../shared/LinkComponent";
import LoadingIndicator from "../../../../../shared/LoadingIndicator";
import Headline from "../../../../../shared/NewHeadline";
import useSnackbar from "../../../../../shared/Snackbar/store";
import Box from "../../../components/Box";
import TermsOfServiceForm from "../Form";
import useStore from "../store";

const EditTermsOfService = () => {
    const { id } = useParams();
    const { loading, fetchTermById, term, setForm, update } = useStore();
    const { notify, confirm } = useSnackbar();

    useEffect(() => {
        fetchTermById(id);
    }, [id]);

    useEffect(() => {
        if (term) {
            setForm("id", term.id);
            setForm("title", term.title);
            setForm("text", term.text);
            setForm("craft_id", term?.craft?.id ?? "");
        }
    }, [term]);

    const onSubmit = (event) => {
        event.preventDefault();
        const onSuccess = () => {
            notify("Erfolgreich", "Vertragsbedingungen wurden gespeichert.");
            return navigate("/ausschreibungsportal/admin/vertragsbedingungen/");
        };
        const onError = (res) => {
            return notify("Fehlgeschlagen", res.message, "error");
        };

        confirm(
            "Vertragsbedingungen aktualisieren",
            "Möchtest du die Vertragsbedingungen aktualisieren?"
        ).then((confirmed) => {
            if (confirmed) {
                update(term.id, onSuccess, onError);
            }
        });
    };

    const onChange = (event) => {
        const target = event.target;
        setForm(target.name, target.value);
    };

    const onFileChange = (event) => {
        const file = event.target.files[0];
        setForm("file", file);
    };

    return (
        <Box>
            <Headline>Vertragsbedingungen bearbeiten</Headline>
            {loading && <LoadingIndicator />}
            {!loading && (
                <TermsOfServiceForm
                    isUpdateForm
                    file={term?.file ?? undefined}
                    onChange={onChange}
                    onFileChange={onFileChange}
                    onSubmit={onSubmit}
                />
            )}
        </Box>
    );
};

export default EditTermsOfService;
