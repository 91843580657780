import React, { useEffect } from "react";

import NewHeadline from "../../../../../shared/NewHeadline";
import useStore from "../../Projects/store";
import Form from "../Form";

const Create = () => {
    const { projects, fetchProjects } = useStore();

    useEffect(() => {
        if (projects.length === 0) {
            fetchProjects();
        }
    }, []);

    return (
        <>
            <NewHeadline>Ausschreibung anlegen</NewHeadline>
            <Form />
        </>
    );
};

export default Create;
