import React, { useEffect } from "react";

import Columns from "../../../../../../shared/Columns";
import LoadingIndicator from "../../../../../../shared/LoadingIndicator";
import Button from "../../../../../../shared/NewForms/Button";
import useSnackbar from "../../../../../../shared/Snackbar/store";
import CraftPicker from "../../../../components/CraftPicker";
import ValidationErrors from "../../../../components/ValidationErrors";
import useDashboardStore from "../../../store";
import useStore from "./store";

const AccountCraftsForm = () => {
    const { notify } = useSnackbar();
    const { setAccountCrafts } = useDashboardStore();
    const {
        userCrafts,
        setUserCrafts,
        loading,
        saving,
        fetchUserCrafts,
        update,
        validationErrors,
    } = useStore();

    useEffect(() => {
        if (userCrafts === undefined) {
            fetchUserCrafts();
        }
    }, []);

    const onChange = (updatedCrafts) => {
        setUserCrafts(updatedCrafts);
    };

    const onSubmit = (event) => {
        event.preventDefault();
        const onSuccess = (updatedCrafts) => {
            setAccountCrafts(updatedCrafts);
            void notify("Erfolgeich", "Deine Gewerke wurden aktualisiert.");
            window.scrollTo(0, 0);
        };
        update(onSuccess);
    };

    if (loading) {
        return <LoadingIndicator />;
    }

    return (
        <form onSubmit={onSubmit}>
            <Columns>
                <Columns.Column>
                    <CraftPicker
                        selectedCrafts={userCrafts}
                        craftsTitle="Ausgewählte Gewerke"
                        addNewTitle="Bitte wähle Deine Gewerke"
                        onChange={onChange}
                    />
                </Columns.Column>
            </Columns>
            <Columns>
                <Columns.Column>
                    <Button
                        disabled={saving}
                        loading={saving}
                        type="submit"
                        centered
                        color="redOutline"
                    >
                        Gewerke speichern
                    </Button>
                </Columns.Column>
            </Columns>

            {validationErrors && (
                <Columns>
                    <Columns.Column>
                        <ValidationErrors error={validationErrors} />
                    </Columns.Column>
                </Columns>
            )}
        </form>
    );
};

export default AccountCraftsForm;
