import React from "react";

import { Router } from "@gatsbyjs/reach-router";

import { Permission, useHoyerAcl } from "../../../../hooks/useHoyerAcl";
import { navigate } from "../../../shared/LinkComponent";
import Crafts from "./Crafts";
import DistributionLists from "./DistributionLists";
import DistributionListsCreate from "./DistributionLists/Create";
import DistributionListsEdit from "./DistributionLists/Edit";
import Landing from "./Landing";
import Projects from "./Projects";
import ProjectsCreate from "./Projects/Create";
import ProjectsEdit from "./Projects/Edit";
import ProjectsShow from "./Projects/Show";
import RegisteredCompanies from "./RegisteredCompanies";
import TendersCreate from "./Tenders/Create";
import TendersEdit from "./Tenders/Edit";
import TermsOfService from "./TermsOfService";
import TermsOfServiceEdit from "./TermsOfService/Edit";

const Admin = () => {
    const { can } = useHoyerAcl();

    if (!can(Permission.AUSSCHREIBUNG_MANAGE)) {
        void navigate("/ausschreibungsportal");
    }

    return (
        <>
            <Router>
                <Landing path="/" />
                <Projects path="projekte/" />
                <ProjectsCreate path="projekte/erstellen" />
                <ProjectsShow path="projekte/:id" />
                <ProjectsEdit path="projekte/:id/bearbeiten" />
                <TendersCreate path="projekte/:projectId/ausschreibungen/erstellen" />
                <TendersEdit path="projekte/:projectId/ausschreibungen/:id/bearbeiten" />
                <Crafts path="gewerke/" />
                <DistributionLists path="verteiler/" />
                <TermsOfService path="vertragsbedingungen" />
                <TermsOfServiceEdit path="vertragsbedingungen/:id" />
                <DistributionListsCreate path="verteiler/erstellen" />
                <DistributionListsEdit path="verteiler/:id/bearbeiten" />
                <RegisteredCompanies path="registrierte-unternehmen" />
            </Router>
        </>
    );
};

export default Admin;
