import create from "zustand";

import { apiFetchProtected } from "../../../../../apiBindings";
import createFieldSlices from "../../../../../utils/store/fields";

const baseUrl = "/call-for-tenders/admin/account";

const useStore = create((set, get) => ({
    ...createFieldSlices("searchTerm", "", set),
    filterActiveCompanies: true,
    loading: false,
    loadingMore: false,
    accounts: [],
    accountMeta: {},

    fetchAccounts: () => {
        set({ loading: true });
        const { getQueryParams } = get();
        const url = baseUrl + "?" + getQueryParams();
        apiFetchProtected(url)
            .then((response) => {
                set({
                    accounts: response.data,
                    accountMeta: response?.meta ?? {},
                });
            })
            .finally(() => set({ loading: false }));
    },

    updateAccountActive: (id, active) => {
        apiFetchProtected(baseUrl, {
            method: "PATCH",
            body: JSON.stringify({ id, active }),
        }).then((response) => {
            console.log(response.data);
            get().fetchAccounts();
        });
    },

    fetchMoreAccounts: () => {
        set({ loadingMore: true });
        const { getQueryParams, accounts } = get();
        const url = baseUrl + "?" + getQueryParams();
        apiFetchProtected(url)
            .then((response) => {
                set({
                    accounts: [...accounts, ...response.data],
                    accountMeta: response?.meta ?? {},
                });
            })
            .finally(() => set({ loadingMore: false }));
    },

    setPage: (page) => {
        const { accountMeta } = get();
        accountMeta.current_page = page;
        set({ accountMeta });
    },

    getQueryParams: () => {
        const { searchTerm, accountMeta } = get();
        let params = {
            page: accountMeta?.current_page ?? 1,
            active: get().filterActiveCompanies,
        };
        if (searchTerm) {
            params["search"] = searchTerm;
        }

        return Object.keys(params)
            .map((key) => key + "=" + params[key])
            .join("&");
    },

    setAccounts: (accounts) => {
        set({ accounts });
    },

    setFilterActiveCompanies: (state) => {
        set({ filterActiveCompanies: state });
    },
}));

export default useStore;
