import React, { useEffect, useState } from "react";

import { css } from "@emotion/react";
import styled from "@emotion/styled";

import NewInput from "../../../../shared/forms/NewInput";
import { SearchInputWithGoogleMapsScript } from "../../../../shared/maps/SearchInput";
import Message from "../../../../shared/Message";
import Button from "../../../../shared/NewForms/Button";
import { germany } from "../../../GasStations/hardCodedMapSearchBounds";
import Modal from "./Modal";

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 0 20px;
`;

const AddressModal = ({ handleModal, store }) => {
    const { data, isModalVisible, setDataField, clearError } = store();

    const [result, setResult] = useState();
    const [streetNumber, setStreetNumber] = useState(data.street_number);

    const onPlacesChange = (placesData) => {
        setResult(placesData[0]);
    };

    const onChange = (event) => {
        setStreetNumber(event.target.value);
    };

    useEffect(() => {
        setStreetNumber(data.street_number);
    }, [data.street_number]);

    const handleClose = () => {
        handleModal();
    };

    const handleSubmit = () => {
        if (result) {
            setDataField("lat", result.geometry.location.lat());
            setDataField("long", result.geometry.location.lng());

            result.address_components.forEach((address) => {
                if (address.types.includes("route")) {
                    setDataField("street", address.long_name);
                }
                if (address.types.includes("street_number")) {
                    setDataField("street_number", address.long_name);
                }
                if (address.types.includes("locality")) {
                    setDataField("city", address.long_name);
                }
                if (address.types.includes("postal_code")) {
                    setDataField("zipcode", address.long_name);
                }
                if (address.types.includes("country")) {
                    setDataField("country", address.long_name);
                }
            });
        }
        if (streetNumber) {
            setDataField("street_number", streetNumber);
        }

        clearError("street");
        clearError("city");
        clearError("zipcode");
        clearError("street_number");

        handleClose();
    };
    return (
        <>
            <Button
                type="button"
                color="darkGrey"
                text
                extraSmall
                underline
                css={css``}
                onClick={() => handleModal()}
            >
                {data.street.length === 0 ||
                data.zipcode.length === 0 ||
                data.city.length === 0
                    ? "Adresse hinzufügen"
                    : "Ändern"}
            </Button>
            <Modal
                isActive={isModalVisible}
                handleClose={handleClose}
                handleModal={handleModal}
                handleSubmit={handleSubmit}
            >
                <ContentContainer>
                    <Message color="grey">
                        Um Deine Adresse zu finden, tippe bitte im Feld „Adresse
                        suchen“ Deine Straße ein. Sollte Deine Adresse nicht
                        unter den Vorschlägen zu finden sein, gebe zusätzlich
                        zur Straße auch Deinen Ort ein. Sobald die richtige
                        Anschrift auftaucht, klicke den entsprechenden Eintrag
                        in der Liste an.
                    </Message>
                    <div>
                        <SearchInputWithGoogleMapsScript
                            bounds={germany}
                            withIcon={false}
                            placeholder="Adresse suchen"
                            onPlacesChange={onPlacesChange}
                            onKeyPress={(e) => {
                                e.key === "Enter" && e.preventDefault();
                            }}
                        />
                        <NewInput
                            name="street_number"
                            placeholder="Hausnummer"
                            value={streetNumber}
                            withField
                            required
                            onChange={onChange}
                        />
                    </div>
                </ContentContainer>
            </Modal>
        </>
    );
};

export default AddressModal;
