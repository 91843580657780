import React, { useEffect } from "react";

import styled from "@emotion/styled";

import LinkComponent from "../../../../shared/LinkComponent";
import LoadingIndicator from "../../../../shared/LoadingIndicator";
import Headline from "../../../../shared/NewHeadline";
import DistributionListItem from "../../components/DistributionListItem";
import useStore from "./store";

const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const DistributionLists = () => {
    const { loading, lists, fetchLists } = useStore();

    useEffect(() => {
        if (lists.length === 0) {
            fetchLists();
        }
    }, []);

    return loading ? (
        <LoadingIndicator />
    ) : (
        <>
            <Headline>E-Mail Verteiler</Headline>
            <ButtonContainer>
                <LinkComponent
                    button
                    link={{
                        href: "/ausschreibungsportal/admin/verteiler/erstellen",
                        text: "Neuen Verteiler anlegen",
                    }}
                />
            </ButtonContainer>
            {lists.length === 0 ? (
                <div>Es wurde noch kein Verteiler angelegt</div>
            ) : (
                lists.map((list) => (
                    <DistributionListItem key={list.id} list={list} />
                ))
            )}
        </>
    );
};

export default DistributionLists;
