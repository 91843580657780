import React, { useEffect } from "react";

import styled from "@emotion/styled";

import { toReadableDate } from "../../../../../utils/DateHelpers";
import Columns from "../../../../shared/Columns";
import LoadingIndicator from "../../../../shared/LoadingIndicator";
import Button from "../../../../shared/NewForms/Button";
import useSnackbar from "../../../../shared/Snackbar/store";
import { TabbedControlled } from "../../../../shared/Tabs";
import Box from "../../components/Box";
import CreateTermsOfService from "./Create";
import useStore from "./store";

const TermsOfService = () => {
    const {
        loading,
        termsOfService,
        fetchTermsOfService,
        deleteTermsOfService,
    } = useStore();
    const { confirm, notify } = useSnackbar();

    useEffect(() => {
        if (termsOfService === undefined) {
            fetchTermsOfService();
        }
    }, []);

    const onDelete = (termsOfService) => {
        confirm(
            "Vertragsbedingungen löschen",
            `Bist du sicher, dass du die Bedingungen "${termsOfService.title}" löschen möchtest?`
        ).then((confirmed) => {
            const onSuccess = () => {
                notify(
                    "Erfolgreich",
                    "Die Vertragsbedingungen wurden gelöscht."
                );
            };
            const onError = () => {
                notify(
                    "Fehlgeschlagen",
                    "Die Vertragsbedingungen konnten nicht gelöscht werden."
                );
            };
            if (confirmed) {
                deleteTermsOfService(termsOfService.id, onSuccess, onError);
            }
        });
    };

    return (
        <>
            <Columns>
                <Columns.Column>
                    <TabbedControlled
                        firstTabIsH1
                        tabs={{
                            general: "Vertragsbedingungen",
                            create: "Neu erstellen",
                        }}
                    >
                        {{
                            general: loading ? (
                                <LoadingIndicator />
                            ) : (
                                <TermsOfServiceList
                                    termsOfService={termsOfService}
                                    onDelete={onDelete}
                                />
                            ),
                            create: <CreateTermsOfService />,
                        }}
                    </TabbedControlled>
                </Columns.Column>
            </Columns>
        </>
    );
};

const TermsOfServiceList = ({ termsOfService, onDelete }) => {
    const ButtonGroup = styled.div`
        display: flex;
        gap: 10px;
    `;

    if (!termsOfService) {
        return null;
    }

    return (
        <>
            {termsOfService.map((terms) => (
                <Box key={terms.id}>
                    <Columns>
                        <Columns.Column>{terms.title}</Columns.Column>
                        <Columns.Column>
                            {terms?.craft?.name ?? "Allgemein"}
                        </Columns.Column>
                        <Columns.Column>
                            {toReadableDate(terms.updated_at)}
                        </Columns.Column>
                        <Columns.Column>
                            <ButtonGroup>
                                <Button onClick={() => onDelete(terms)} small>
                                    Löschen
                                </Button>
                                <Button
                                    to={`/ausschreibungsportal/admin/vertragsbedingungen/${terms.id}`}
                                    color="redOutline"
                                    small
                                >
                                    Bearbeiten
                                </Button>
                            </ButtonGroup>
                        </Columns.Column>
                    </Columns>
                </Box>
            ))}
        </>
    );
};

export default TermsOfService;
